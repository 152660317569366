import { FC } from 'react';
import { MotionButton } from 'components/MotionButton';
import { useNavigate } from 'react-router-dom';

import style from 'assets/styles/notFoundPage.module.scss';

interface Props {
	element: string;
}

export const NotFoundElement: FC<Props> = ({ element }) => {
	let navigate = useNavigate();

	return (
		<div className={style.containerNotFoundElement}>
			<div className={style.content}>
				<div className={style.image}></div>
				<p className={style.mainText}>That {element} doesn't exist</p>
				<p className={style.subtleText}>
					This is not the web page your are looking for
				</p>
				<MotionButton
					shape="round"
					size="large"
					htmlType="submit"
					width="10rem"
					height="2.375rem"
					fontWeight="700"
					onClick={() => {
						navigate(-1);
					}}
				>
					Back
				</MotionButton>
			</div>
		</div>
	);
};
