import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { SubscriptionLicense } from './SubscriptionLicense';
import { IconEmptyCube } from 'assets/icons/IconEmptyCube';
import style from 'assets/styles/licensingPage.module.scss';
import {
	ApplicationDto,
	FullDataLicenseDto,
	RequestType,
	SubscriptionDto,
} from 'api';
import { App, Empty, Spin } from 'antd';
import { McButton, McDialog } from 'components/mc';
import { RequestTypes } from 'types/api';
import { getPublicImageURL } from 'services/ApiService';
import { useQuery } from '@tanstack/react-query';
import applicationsService from 'services/ApplicationsService';
import modulesService from 'services/ModulesService';
import subscriptionsService from 'services/SubscriptionsService';
import { infoNotification, mcErrorNotification } from 'utils/Notifications';
import ActivationWizard from 'modules/ActivationWizard/ActivationWizard';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import LicenseRequestTable from 'modules/LicenseRequestTable';

dayjs.extend(utc);
dayjs.extend(timezone);

interface OpenedSubscriptionProps {
	subscription: SubscriptionDto;
	isLicensesDisabled: boolean;
}

export const OpenedCustomerSubscription: React.FC<OpenedSubscriptionProps> = ({
	subscription,
	isLicensesDisabled,
}) => {
	const [showLicensingRequestTable, setShowLicensingRequestTable] =
		useState(false);
	const [showLicenseRequest, setShowLicenseRequest] = useState<RequestType>();
	const [selectedLicenseIds, setSelectedLicenseIds] = useState<number[]>([]);
	const [, setSelContainerID] = useState<string | undefined>(undefined);
	const [deactivationEffect, setDeactivationEffect] = useState(false);
	const { notification } = App.useApp();

	const {
		data: licenses,
		isLoading: licensesLoading,
		error: getLicensesError,
	} = useQuery({
		queryKey: ['subscriptions', subscription.id, 'customer', 'licenses'],
		queryFn: () =>
			subscriptionsService
				.getAllLicenses(subscription.id, false)
				.then((res) => res.data),
		initialData: [],
		enabled: !!subscription,
	});

	const {
		data: applications,
		isLoading: appsLoading,
		error: getApplicationsError,
	} = useQuery({
		queryKey: ['applications'],
		queryFn: () =>
			applicationsService
				.getApplicationsList(0, 50, undefined)
				.then((res) => res.data.content),
		initialData: [],
	});

	const {
		data: modules,
		isLoading: modulesLoading,
		error: getModulesError,
	} = useQuery({
		queryKey: ['modules'],
		queryFn: () =>
			modulesService
				.getModulesList(0, 50, undefined)
				.then((res) => res.data.content),
		initialData: [],
	});

	useEffect(() => {
		if (!getLicensesError) return;
		notification.warning(
			mcErrorNotification('Warning', getLicensesError, 'fetch', 'licenses')
		);
	}, [getLicensesError, notification]);

	useEffect(() => {
		if (!getApplicationsError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				getApplicationsError,
				'fetch',
				'applications'
			)
		);
	}, [getApplicationsError, notification]);

	useEffect(() => {
		if (!getModulesError) return;
		notification.warning(
			mcErrorNotification('Warning', getModulesError, 'fetch', 'modules')
		);
	}, [getModulesError, notification]);

	// ============================================================================================================================================================ Actual license dtos of both available and activated
	const [activatedLicenses, availableLicenses] = useMemo<
		[FullDataLicenseDto[], FullDataLicenseDto[]]
	>(
		() =>
			licenses.reduce(
				([activated, available], license) =>
					!!license.expiryDate &&
					license.expiryDate.length > 0 &&
					license.licenseStatus === 'ACTIVATED' &&
					(dayjs.utc().isBefore(dayjs.utc(license.expiryDate), 'day') ||
						dayjs.utc().isSame(dayjs.utc(license.expiryDate), 'day')) // TODO: might not update for utc() since its not part of dep array
						? [[...activated, license], available]
						: [activated, [...available, license]],
				[[] as FullDataLicenseDto[], [] as FullDataLicenseDto[]]
			) as [FullDataLicenseDto[], FullDataLicenseDto[]],
		[licenses]
	);

	const [activatedLicensesForContainer, activatedLicensesForOther] = useMemo(
		() =>
			activatedLicenses.reduce(
				([current, other], license) => [[...current, license], other],
				[[] as FullDataLicenseDto[], [] as FullDataLicenseDto[]]
			),
		[activatedLicenses]
	);

	const activatedModules = useMemo(
		() =>
			activatedLicensesForContainer.flatMap((l) => [
				modules.find((m) => m.name === l.moduleName)?.id,
			]),
		[activatedLicensesForContainer, modules]
	);

	const activatedModulesForAnotherContainer = useMemo(
		() =>
			activatedLicensesForOther.flatMap((l) => [
				modules.find((m) => m.name === l.moduleName)?.id,
			]),
		[activatedLicensesForOther, modules]
	);

	const selectedModules = useMemo(
		() =>
			availableLicenses.flatMap((l) =>
				selectedLicenseIds.includes(l.id)
					? [modules.find((m) => m.name === l.moduleName)?.id]
					: []
			),
		[availableLicenses, selectedLicenseIds, modules]
	);

	const selectedActivatedModules = useMemo(
		() =>
			activatedLicenses.flatMap((l) =>
				selectedLicenseIds.includes(l.id)
					? [modules.find((m) => m.name === l.moduleName)?.id]
					: []
			),
		[activatedLicenses, selectedLicenseIds, modules]
	);

	const availableModules = useMemo(
		() =>
			availableLicenses.flatMap((l) => [
				modules.find((m) => m.name === l.moduleName)?.id,
			]),
		[availableLicenses, modules]
	);

	const selectedLicenses = useMemo(() => {
		return !!licenses && licenses.length > 0
			? licenses.filter((license) => selectedLicenseIds.includes(license.id))
			: [];
	}, [licenses, selectedLicenseIds]);

	/*
	useEffect(() => {
		if (!containersLoading && containers.length === 1) {
			// Automatically select the first container if it's the only one
			setSelContainerID(containers[0].name);
		}
	}, [containersLoading, containers]);
	*/

	type AppStatus = 'activated' | 'available' | 'unavailable' | 'unlicensed';
	const appsWithStatus: [ApplicationDto, AppStatus, boolean][] = useMemo(() => {
		const appStatus = (appModules: number[]): [AppStatus, boolean] => {
			if (appModules.every((m) => activatedModules.includes(m))) {
				const selected = appModules.some((m) =>
					selectedActivatedModules.includes(m)
				);
				return ['activated', selected];
			}

			if (
				appModules.every(
					(m) => availableModules.includes(m) || activatedModules.includes(m)
				)
			) {
				const selected = appModules.every(
					(m) => selectedModules.includes(m) || activatedModules.includes(m)
				);
				return ['available', selected];
			}

			if (
				appModules.every(
					(m) =>
						availableModules.includes(m) ||
						activatedModulesForAnotherContainer.includes(m)
				)
			) {
				return ['unavailable', false];
			}

			return ['unlicensed', false];
		};
		return applications.map((app) => [app, ...appStatus(app.modulesIds ?? [])]);
	}, [
		applications,
		activatedModules,
		selectedActivatedModules,
		activatedModulesForAnotherContainer,
		selectedModules,
		availableModules,
	]);

	const togglePending = (
		app: ApplicationDto,
		status: AppStatus,
		currentlySelected: boolean
	) => {
		const moduleIds = app.modulesIds ?? [];
		const moduleNames = modules.flatMap((m) =>
			moduleIds.includes(m.id) ? [m.name] : []
		);
		const toToggle = (
			status === 'available' ? availableLicenses : activatedLicenses
		).flatMap((l) => (moduleNames.includes(l.moduleName) ? [l.id] : []));
		setSelectedLicenseIds((selected) => {
			const newLicenses = currentlySelected
				? toToggle.filter((m) => !selected.includes(m))
				: toToggle;
			const otherLicenses = selected.filter((m) => !toToggle.includes(m));
			return [...newLicenses, ...otherLicenses];
		});
	};

	const [modActiveEnabled, modAvailableEnabled] = useMemo(() => {
		if (!selectedLicenseIds) return [false, false];

		const modActive = activatedLicensesForContainer.some((l) =>
			selectedLicenseIds.includes(l.id)
		);
		const modAvailable = availableLicenses.some((l) =>
			selectedLicenseIds.includes(l.id)
		);

		// Selecting both activated and available licenses is not allowed
		if (modActive && modAvailable) return [false, false];

		return [modActive, modAvailable];
	}, [
		//selContainerName,
		activatedLicensesForContainer,
		availableLicenses,
		selectedLicenseIds,
	]);

	const [displayActivationWizard, setDisplayActivationWizard] =
		useState<boolean>(false);

	const deselectAllLicenses = useCallback(() => {
		setSelectedLicenseIds([]);
	}, []);

	const currentContainerId =
		modActiveEnabled && !!selectedLicenses && selectedLicenses.length > 0
			? selectedLicenses[0].containerName
			: undefined;

	return (
		<>
			<Spin spinning={appsLoading || licensesLoading || modulesLoading}>
				<div
					className={`${style.appsSection} ${
						deactivationEffect ? style.deactivation : ''
					}`}
				>
					{appsWithStatus.map(([app, status, selected]) => (
						<div
							onClick={() => {
								if (!modActiveEnabled) togglePending(app, status, selected);
								else
									notification.info(
										infoNotification('Deselect any active licenses first!')
									);
							}}
							key={app.id}
							className={`${style.appItem} ${style[status]} ${
								selected ? style.selected : ''
							}`}
						>
							{app.iconImagePath === undefined || app.iconImagePath === null ? (
								<IconEmptyCube />
							) : (
								<img
									className={style.appIcon}
									alt=""
									src={getPublicImageURL(app.iconImagePath)}
								/>
							)}
							{app.name}
						</div>
					))}
				</div>
			</Spin>
			<div className={style.openedSubscriptionWrapper}>
				<div className={style.activatedModulesSection}>
					<h4 className={style.subtitle}>Activated modules</h4>
					{activatedLicenses.length ? (
						activatedLicenses?.map((license) => (
							<div key={license.id} className={style.dividerElements}>
								<SubscriptionLicense
									subscription={subscription}
									license={license}
									setSelectedToUpdate={setSelectedLicenseIds}
									selectedToUpdate={selectedLicenseIds}
									currentContainerID={currentContainerId}
									setCurrentContainerID={setSelContainerID}
									modActiveEnabled={modActiveEnabled}
									modAvailableEnabled={modAvailableEnabled}
									isDisabled={isLicensesDisabled}
								/>
							</div>
						))
					) : (
						<Empty
							description="You currently have no activated modules. Please select the modules below and request for an activation."
							imageStyle={{ display: 'none' }}
							image={Empty.PRESENTED_IMAGE_SIMPLE}
						/>
					)}
				</div>
				<div className={style.divider}></div>
				<div className={style.availableModulesSection}>
					<h4 className={style.subtitle}>Available modules</h4>
					{availableLicenses.length ? (
						availableLicenses?.map((license) => (
							<div key={license.id} className={style.dividerElements}>
								<SubscriptionLicense
									subscription={subscription}
									license={license}
									setSelectedToUpdate={setSelectedLicenseIds}
									selectedToUpdate={selectedLicenseIds}
									currentContainerID={currentContainerId}
									setCurrentContainerID={setSelContainerID}
									modActiveEnabled={modActiveEnabled}
									modAvailableEnabled={modAvailableEnabled}
									isDisabled={isLicensesDisabled}
								/>
							</div>
						))
					) : (
						<Empty
							description="No available modules"
							imageStyle={{ display: 'none' }}
							image={Empty.PRESENTED_IMAGE_SIMPLE}
						/>
					)}
				</div>

				<div className={style.buttonsSection}>
					<div style={{ marginRight: 'auto' }}>
						<McButton
							onClick={() => setShowLicensingRequestTable((prev) => !prev)}
						>
							View requests
						</McButton>
					</div>
					<McButton
						primary
						disabled={
							!modAvailableEnabled ||
							subscription.status === 'INACTIVE' ||
							isLicensesDisabled
						}
						onClick={() => {
							setDisplayActivationWizard(true);
							setShowLicenseRequest(RequestTypes.ACTIVATION);
						}}
					>
						Activate
					</McButton>
					<McButton
						disabled={
							!modActiveEnabled ||
							subscription.status === 'INACTIVE' ||
							isLicensesDisabled
						}
						onClick={() => {
							setDisplayActivationWizard(true);
							setShowLicenseRequest(RequestTypes.EXTENDACTIVATION);
						}}
					>
						Extend activation
					</McButton>
					<McButton
						disabled={
							!modActiveEnabled ||
							subscription.status === 'INACTIVE' ||
							isLicensesDisabled
						}
						onMouseEnter={() => setDeactivationEffect(true)}
						onMouseLeave={() => setDeactivationEffect(false)}
						onClick={() => {
							setDisplayActivationWizard(true);
							setShowLicenseRequest(RequestTypes.DEACTIVATION);
						}}
					>
						Deactivate
					</McButton>
				</div>
			</div>
			{showLicensingRequestTable && (
				<McDialog
					modal
					title={'Licensing Requests'}
					footer={
						<McButton
							onClick={(e) => {
								e.stopPropagation();
								setShowLicensingRequestTable(false);
							}}
						>
							Close
						</McButton>
					}
				>
					<LicenseRequestTable subscription={subscription} />
				</McDialog>
			)}
			{displayActivationWizard && !!showLicenseRequest && (
				<ActivationWizard
					displayActivationWizard={displayActivationWizard}
					setDisplayActivationWizard={setDisplayActivationWizard}
					subscription={subscription}
					licenses={selectedLicenses}
					requestType={showLicenseRequest}
					deselectAllLicenses={deselectAllLicenses}
				/>
			)}
		</>
	);
};
