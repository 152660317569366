import { UserDto } from 'api';

export type User = UserDto | Guest;

export interface Guest {
	role: 'GUEST';
}

export const guestUser: Guest = {
	role: 'GUEST',
};

export type Users = User[];

export const isUserDto = (user: User): user is UserDto => {
	return (user as UserDto).id !== undefined;
};
