import React, { useState, useEffect, useMemo } from 'react';

import { InputField, PictureField } from 'components/fields';
import { TagField } from 'components/fields/TagField';
import { SelectField } from 'components/selects';
import style from 'assets/styles/editAddElementForm.module.scss';
import componentStyle from 'assets/styles/components.module.scss';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { editUserSchema } from 'validations/FormValidation';
import { UserDto, UserUpdateDto, UserSignDto } from 'api';
import { UserStatus, UserStatuses } from 'types/api';
import { useNavigate } from 'react-router-dom';
import SelectOrganization from 'components/selects/SelectOrganization';
import { App, Spin } from 'antd';
import {
	mcErrorNotification,
	saveSuccessNotification,
} from 'utils/Notifications';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import usersService from 'services/UsersService';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { hasWritePermission } from 'utils/permissions';
import { McButton } from 'components/mc';

interface EditUserFormProps {
	user: UserDto | 'new';
	activatedContainers?: number;
	onHide?: () => void;
}

interface FormValues {
	firstName: string;
	lastName: string;
	email: string;
	company: string;
	department: string;
	containerActivationLimit: number;
	activatedContainers: number;
}

export const EditUserForm: React.FC<EditUserFormProps> = (props) => {
	const { onHide, activatedContainers } = props;
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { notification } = App.useApp();

	const {
		formState: { errors },
		handleSubmit,
		control,
		setValue,
		setError: setFormError,
	} = useForm<FormValues>({
		mode: 'onBlur',
		resolver: yupResolver(editUserSchema),
	});

	const isNew = props.user === 'new';
	const user = useMemo(
		() =>
			typeof props.user === 'string'
				? {
						email: '',
						status: UserStatuses.PENDING,
						company: '',
						department: '',
						firstName: '',
						lastName: '',
						avatarPath: undefined,
						organizationId: undefined,
						containerActivationLimit: 0,
						roles: [],
				  }
				: props.user,
		[props.user]
	);

	const {
		data: roles,
		error: fetchUserWithRolesError,
		isLoading: loadingUserWithRoles,
		isFetching: fetchingUserWithRoles,
	} = useQuery({
		queryKey: ['users', user.email, 'roles'],
		queryFn: () =>
			usersService
				.getUserAndRolesByEmail(user.email)
				.then((res) => res.data.roles ?? []),
		initialData: [],
	});

	useEffect(() => {
		if (isNew) return;
		if (!fetchingUserWithRoles && roles.length === 0) {
			notification.warning({
				message: 'Warning',
				description:
					'Unable to fetch roles. Please try closing and opening the user details again.',
			});
		}

		if (!fetchUserWithRolesError) return;
		notification.error(
			mcErrorNotification(
				'Error',
				fetchUserWithRolesError,
				'fetch',
				'user roles'
			)
		);
	});

	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'users');

	useEffect(() => {
		const opts = {
			shouldValidate: false,
			shouldDirty: false,
		};

		setValue('firstName', user?.firstName ?? '', opts);
		setValue('lastName', user?.lastName ?? '', opts);
		setValue('email', user?.email ?? '', opts);
		setValue('company', user?.company ?? '', opts);
		setValue('department', user?.department ?? '', opts);
		setValue(
			'containerActivationLimit',
			user?.containerActivationLimit ?? 0,
			opts
		);
	}, [user, setValue, isNew, activatedContainers]);

	const [status, setStatus] = useState<UserStatus | undefined>(
		user?.status ?? UserStatuses.VERIFIED
	);

	const [organizationId, setOrganizationId] = useState<number | undefined>(
		isNew ? undefined : user.organizationId
	);
	const [selectedImage, setSelectedImage] = useState<Blob | undefined>();
	const [isAvatarDeleted, setIsAvatarDeleted] = useState(false);
	const [containerActivationLimit] = useState<number>(
		user?.containerActivationLimit ?? 0
	);

	const { mutate: editUser } = useMutation({
		mutationFn: (data: FormValues) => {
			const firstChanged = data.firstName.trim() !== user.firstName;
			const lastChanged = data.lastName.trim() !== user.lastName;
			const emailChanged = data.email.trim() !== user.email;
			const compChanged = data.company.trim() !== user.company;
			const depChanged = data.department.trim() !== user.department;
			const statusChanged = status !== user.status;
			const orgChanged = organizationId !== user.organizationId;
			const imgChanged = selectedImage instanceof File;
			const limitChanged =
				data.containerActivationLimit !== user.containerActivationLimit;

			if (
				!firstChanged &&
				!lastChanged &&
				!emailChanged &&
				!compChanged &&
				!depChanged &&
				!statusChanged &&
				!imgChanged &&
				!isAvatarDeleted &&
				!orgChanged &&
				!limitChanged
			) {
				onHide?.();
				return Promise.reject();
			}
			const userDto: UserUpdateDto = {
				firstName: firstChanged ? data.firstName.trim() : undefined,
				lastName: lastChanged ? data.lastName.trim() : undefined,
				emailToUpdate: emailChanged ? data.email.trim() : undefined,
				company: compChanged ? data.company.trim() : undefined,
				department: depChanged ? data.department.trim() : undefined,
				status: statusChanged ? status : undefined,
				organizationId: organizationId,
				containerActivationLimit: limitChanged
					? data.containerActivationLimit
					: undefined,
			};

			const deleteAvatar: Promise<any> = isAvatarDeleted
				? usersService.deleteUsersProfilePicture(user.email)
				: Promise.resolve();
			return Promise.all([
				usersService.updateUserForm(user.email, userDto, selectedImage),
				deleteAvatar,
			]);
		},
		onSuccess: (_, data) => {
			notification.success(
				saveSuccessNotification(data.firstName + ' ' + data.lastName)
			);
			if (isAvatarDeleted) setIsAvatarDeleted(false);
			queryClient.invalidateQueries({ queryKey: ['users'] });
			onHide?.();
		},
		onError: (error: unknown) => {
			notification.error(mcErrorNotification('Error', error, 'update', 'user'));
			setFormError('email', {
				type: 'custom',
				message: 'Another user with the provided email already exist!',
			});
		},
	});

	const { mutate: completeReview, isPending: isPendingCompleteReview } =
		useMutation({
			mutationFn: (userStatus: UserStatus) => {
				const userDto: UserUpdateDto = {
					firstName: undefined,
					lastName: undefined,
					emailToUpdate: undefined,
					company: undefined,
					department: undefined,
					status: userStatus,
					organizationId: user.organizationId,
					containerActivationLimit: user.containerActivationLimit,
				};

				return usersService.updateUserForm(user.email, userDto, selectedImage);
			},
			onSuccess: () => {
				notification.success(saveSuccessNotification());
				queryClient.invalidateQueries({ queryKey: ['users'] });
				onHide?.();
			},
			onError: (err: unknown) =>
				notification.error(
					mcErrorNotification('Error', err, 'complete', 'review')
				),
		});

	const { mutate: addUser } = useMutation({
		mutationFn: (data: FormValues) => {
			const userDto: UserSignDto = {
				firstName: data.firstName.trim(),
				lastName: data.lastName.trim(),
				email: data.email.trim(),
				company: data.company.trim(),
				department: data.department.trim(),
				organizationId: organizationId,
				containerActivationLimit: containerActivationLimit,
			};

			return usersService.createAccountForm(userDto, selectedImage);
		},
		onSuccess: (_, data) => {
			notification.success(
				saveSuccessNotification(data.firstName + ' ' + data.lastName)
			);
			queryClient.invalidateQueries({ queryKey: ['users'] });
			onHide?.();
		},
		onError: (error: unknown) => {
			notification.error(mcErrorNotification('Error', error, 'add', 'user'));
			setFormError('email', {
				type: 'custom',
				message: 'Another user with the provided email already exist!',
			});
		},
	});

	const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		onHide?.();
		navigate('/admin/users');
	};

	return (
		<form
			className={isNew ? style.addFormWrapper : style.editFormWrapper}
			onSubmit={handleSubmit((data) => {
				isNew ? addUser(data) : editUser(data);
			})}
		>
			<PictureField
				selectedImage={selectedImage}
				setSelectedImage={setSelectedImage}
				onlyToDeleteAvatar={true}
				isAvatar={true}
				existingImagePath={user.avatarPath}
				isImageDeleted={isAvatarDeleted}
				setIsImageDeleted={setIsAvatarDeleted}
			/>
			<div className={style.editForm}>
				<div className={style.column}>
					<Controller
						name="firstName"
						control={control}
						render={({ field }) => (
							<InputField
								placeholder={'First name'}
								tabIndex={10}
								{...field}
								label={'First Name'}
								error={errors.firstName === undefined ? false : true}
								errorMessage={errors.firstName?.message}
							/>
						)}
					/>

					<Controller
						name="email"
						control={control}
						render={({ field, fieldState: { error } }) => (
							<InputField
								placeholder={'Email'}
								tabIndex={30}
								{...field}
								label={'Email'}
								error={!!error || !!errors.email}
								errorMessage={errors.email?.message || error?.message}
							/>
						)}
					/>

					<SelectOrganization
						selectedId={organizationId}
						setSelectedId={setOrganizationId}
						tabIndex={80}
					/>

					<div className={style.row} style={{ marginTop: '1.5rem' }}>
						<div className="w-full">
							<Controller
								name="activatedContainers"
								control={control}
								render={({ field }) => (
									<div className={style.inputWrapper}>
										<label>Activated Containers</label>
										<InputField
											{...field}
											value={(activatedContainers ?? 0).toString()}
											disabled
											type="text"
										/>
									</div>
								)}
							/>
						</div>
					</div>
				</div>
				<div className={style.column}>
					<Controller
						name="lastName"
						control={control}
						render={({ field }) => (
							<InputField
								placeholder={'Last name'}
								tabIndex={20}
								{...field}
								label={'Last Name'}
								error={errors.lastName === undefined ? false : true}
								errorMessage={errors.lastName?.message}
							/>
						)}
					/>
					<Controller
						name="company"
						control={control}
						render={({ field }) => (
							<InputField
								placeholder={'Company'}
								tabIndex={40}
								{...field}
								label={'Company'}
								error={errors.company === undefined ? false : true}
								errorMessage={errors.company?.message}
							/>
						)}
					/>

					<Controller
						name="department"
						control={control}
						render={({ field }) => (
							<InputField
								placeholder={'Department'}
								tabIndex={60}
								{...field}
								label={'Department'}
								error={errors.department === undefined ? false : true}
								errorMessage={errors.department?.message}
							/>
						)}
					/>
					<div className="w-full">
						<Controller
							name="containerActivationLimit"
							control={control}
							render={({ field }) => (
								<InputField
									label="Activated Containers Limit"
									{...field}
									tabIndex={100}
									type="number"
									value={
										field.value !== undefined &&
										field.value >= (activatedContainers || 0)
											? field.value
											: activatedContainers || ''
									}
									onChange={(e) => {
										const newValue = Math.max(
											Number(e.target.value),
											activatedContainers || 0
										);
										field.onChange(newValue);
									}}
									error={!!errors.containerActivationLimit}
									errorMessage={errors.containerActivationLimit?.message}
								/>
							)}
						/>
					</div>
				</div>
			</div>
			<div>
				<TagField
					isLoading={(!isNew && fetchingUserWithRoles) || loadingUserWithRoles}
					label="Roles"
					values={roles}
					noTagsMessage="No roles available."
				/>
			</div>
			<div className={style.column}>
				<SelectField
					defaultValue={status}
					options={[
						{ value: UserStatuses.REVIEWING, label: 'Reviewing' },
						{ value: UserStatuses.PENDING, label: 'Pending' },
						{ value: UserStatuses.VERIFIED, label: 'Verified' },
						{ value: UserStatuses.ACTIVE, label: 'Active' },
						{ value: UserStatuses.INACTIVE, label: 'Inactive' },
					]}
					label={'Status'}
					tabIndex={70}
					setSelectedField={setStatus}
				/>
				{status === UserStatuses.REVIEWING && !isNew && (
					<div className={style.statusButtonsWrapper}>
						{isPendingCompleteReview ? (
							<div>
								<Spin spinning={true} />
							</div>
						) : (
							<>
								<button
									className={componentStyle.approveButton}
									onClick={() => {
										completeReview(UserStatuses.PENDING);
									}}
									disabled={isPendingCompleteReview}
									type="button"
								>
									Approve user account
								</button>
								<button
									className={componentStyle.rejectButton}
									onClick={() => {
										completeReview(UserStatuses.INACTIVE);
									}}
									type="button"
									disabled={isPendingCompleteReview}
								>
									Reject user account
								</button>
							</>
						)}
					</div>
				)}
			</div>
			<div className={style.buttonsWrapper}>
				<McButton onClick={cancel}>Cancel</McButton>
				<McButton primary type="submit" disabled={!canEdit}>
					Save
				</McButton>
			</div>
		</form>
	);
};
