import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { UsersResponse } from 'types/response/UsersResponse';
import { RootState } from 'store';
import { createAppAsyncThunk, setLoadingState } from 'utils/rtk';
import { getErrorMessageAnd, rejectionError } from 'utils/errors';
import usersService from 'services/UsersService';
import { UserStatus } from 'types/api';
import { ErrorDTO } from 'api';

export interface UsersState {
	users: UsersResponse;
	loading: boolean;
	error: ErrorDTO | null;
}

const initialState: UsersState = {
	users: {} as UsersResponse,
	loading: true,
	error: null,
};

type GetUsersParamsLoose = Parameters<typeof usersService.getUsersList>;
type GetUsersParams = [
	GetUsersParamsLoose[0], // Page
	GetUsersParamsLoose[1], // Size
	UserStatus | undefined,
	GetUsersParamsLoose[3], // Filter Query
	GetUsersParamsLoose[4]
];

export const getUsers = createAppAsyncThunk(
	'users/getUsers',
	async (params: GetUsersParams, { rejectWithValue }) =>
		await usersService
			.getUsersList(...params)
			.catch(getErrorMessageAnd(rejectWithValue))
);

const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getUsers.pending, setLoadingState)
			.addCase(getUsers.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.users = payload.data;
			})

			.addMatcher(isAnyOf(getUsers.rejected), (state, action) => {
				state.error = action.payload ?? rejectionError;
				state.loading = false;
			});
	},
});

export const usersState = (state: RootState) => state.users;

export default usersSlice.reducer;
