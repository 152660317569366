import { invoke } from '@tauri-apps/api/tauri';
import {
	InstalledEngine,
	FileSource,
	SessionFile,
	CmContainer,
	ContainerType,
} from './types';
import { AxiosResponse } from 'axios';
import { downloadFile } from './downloadHelper';

export const isDesktop = '__TAURI__' in window;

interface DesktopService {
	launchBrowser(url: string): Promise<void>;
	showDirectoryInShell(path: string): Promise<void>;
	getInstalledEngines(): Promise<InstalledEngine[]>;
	getRecentFiles(): Promise<SessionFile[]>;
	getUserTemplates(): Promise<SessionFile[]>;
	getRecentFolder(): Promise<string>;
	importLifTemplate(): Promise<void>;
	launchApplication(engineName: string, applicationId: string): Promise<void>;
	openSessionFile(
		engineName: string,
		fileName: string,
		fileSource: FileSource,
		applicationId?: string
	): Promise<void>;
	createContextFile(
		mask: number,
		serial: number
		//firmCode: number
	): Promise<number[]>;
	importUpdateFile(
		mask: number,
		serial: number,
		//firmCode: number,
		update: String
	): Promise<void>;
	createReceiptFile(
		mask: number,
		serial: number
		//firmCode: number
	): Promise<number[]>;
	getCodemeterContainers(
		firmCode: number,
		containerType: ContainerType
	): Promise<CmContainer[]>;
	installEngine(installFile: string): Promise<number>;
	uninstallEngine(engineName: string): Promise<number>;
	downloadFileToDesktop(
		downloadResponse: Promise<AxiosResponse>,
		fileTypeName?: string,
		extensions?: string[],
		defaultFileName?: string
	): Promise<void>;
	saveFile(fileName: string, content: string): Promise<void>;
}

const tauriDesktopService: DesktopService = {
	launchBrowser: (url: string) => invoke('launch_browser', { url }),
	installEngine: (installFile: string) =>
		invoke('install_engine', { installFile }),
	uninstallEngine: (engineName: string) =>
		invoke('uninstall_engine', { engineName }),
	getInstalledEngines: () => invoke('get_installed_engines'),
	getRecentFiles: () => invoke('get_recent_files'),
	getUserTemplates: () => invoke('get_user_templates'),
	getRecentFolder: () => invoke('get_recent_folder'),
	importLifTemplate: () => invoke('import_lif_template'),
	importUpdateFile: (mask, serial, update: String) =>
		invoke('import_update_file', { mask, serial, update }),
	createContextFile: (mask: number, serial: number) =>
		invoke('create_context_file', { mask, serial }),
	createReceiptFile: (mask, serial) =>
		invoke('create_receipt_file', { mask, serial }),
	launchApplication: (engineName: string, applicationId: string) =>
		invoke('launch_application', { engineName, applicationId }),
	openSessionFile: (engineName: string, fileName, fileSource, applicationId) =>
		invoke('open_session_file', {
			engineName,
			fileName,
			fileSource,
			applicationId,
		}),
	showDirectoryInShell: (path) => invoke('show_directory_in_shell', { path }),
	getCodemeterContainers: (firmCode, containerType) =>
		invoke('get_codemeter_containers', {
			firmCode,
			containerType,
		}),
	downloadFileToDesktop: downloadFile,
	saveFile: (fileName, content) => invoke('save_file', { fileName, content }),
};

const notSupported = () => {
	throw Error('Not supported on web!');
};
const webDesktopService: DesktopService = {
	getInstalledEngines: () => Promise.resolve([]),
	getRecentFiles: () => Promise.resolve([]),
	getUserTemplates: () => Promise.resolve([]),
	saveFile: notSupported,
	createContextFile: notSupported,
	createReceiptFile: notSupported,
	importLifTemplate: notSupported,
	importUpdateFile: notSupported,
	getRecentFolder: notSupported,
	launchApplication: notSupported,
	installEngine: notSupported,
	uninstallEngine: notSupported,
	showDirectoryInShell: notSupported,
	openSessionFile: notSupported,
	launchBrowser: notSupported,
	downloadFileToDesktop: notSupported,
	getCodemeterContainers: notSupported,
};

export const ipc: DesktopService = isDesktop
	? tauriDesktopService
	: webDesktopService;

export const getInstalledEngines = ipc.getInstalledEngines;
export const getRecentFiles = ipc.getRecentFiles;
export const getUserTemplates = ipc.getUserTemplates;
export const getRecentFolder = ipc.getRecentFolder;
export const launchApplication = ipc.launchApplication;
