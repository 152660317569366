import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { CurrentUserUpdateDto, ErrorDTO } from 'api';
import { isDesktop } from 'desktop';
import { setLocalSetting } from 'settings';
import { authService } from 'services/AuthService';
import usersService from 'services/UsersService';
import { RootState } from 'store';
import { User, guestUser } from 'types/user';
import { getErrorMessageAnd, rejectionError } from 'utils/errors';
import { createAppAsyncThunk } from 'utils/rtk';
import { IdToken, useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from 'jwt-decode';

export interface AuthState {
	user: User;
	isAuth: boolean;
	isOnline: boolean;
	loading: boolean;
	isRequest: boolean;
	error: ErrorDTO | null;
	isGuest: boolean;
	isAdmin: boolean;
	permissions: string[];
}

const initialState: AuthState = {
	user: {} as User,
	isAuth: false,
	isOnline: navigator.onLine,
	loading: true,
	error: null,
	isRequest: false,
	isGuest: false,
	isAdmin: false,
	permissions: [],
};

export const loginUsingToken = createAppAsyncThunk(
	'auth/login2',
	(idClaims: IdToken | undefined, { rejectWithValue }) =>
		authService
			.login({
				givenName: idClaims?.given_name,
				familyName: idClaims?.family_name,
			})
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const logout = createAppAsyncThunk<void, { isGuest: boolean }>(
	'auth/logout',
	async ({ isGuest }) => {
		if (!isGuest) {
			if (isDesktop) {
				setLocalSetting('accessToken', null);
				setLocalSetting('refreshToken', null);
				setLocalSetting('apiSession', null);
			}
		}
		setLocalSetting('isGuest', false);
	}
);

export const loginAsGuest = createAppAsyncThunk<void>(
	'auth/loginAsGuest',
	async () => {
		logout({ isGuest: false });
		setLocalSetting('isGuest', true);
	}
);

export const checkAuth = createAppAsyncThunk(
	'auth/checkAuth',
	(_, { rejectWithValue }) =>
		usersService.getCurrentUser().catch(getErrorMessageAnd(rejectWithValue))
);

export const fetchAndSetAdminPermissions = createAppAsyncThunk(
	'auth/fetchAndSetAdminPermissions',
	async (token: string, { dispatch }) => {
		const decodedToken: any = jwtDecode(token);

		const permissions = decodedToken.permissions || [];

		// Check if the token contains 'ROLE_ADMIN' permission
		const isAdmin = permissions?.includes('ROLE_ADMIN');

		// Dispatch the action to set admin status and permissions
		dispatch(setAdminStatus(isAdmin));
		dispatch(setPermissions(permissions));

		return { isAdmin, permissions };
	}
);

interface UpdateCurrentUserParams {
	dto?: CurrentUserUpdateDto;
	image?: Blob;
}

export const updateCurrentUser = createAppAsyncThunk(
	'auth/updateCurrentUser',
	({ dto, image }: UpdateCurrentUserParams, { rejectWithValue }) =>
		usersService
			.updateCurrentUserForm(dto, image)
			.catch(getErrorMessageAnd(rejectWithValue))
);

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		updateOnlineStatus(state, { payload }) {
			state.isOnline = payload;
		},
		guestMode(state) {
			state.loading = false;
			state.isAuth = true;
			state.user = guestUser;
			state.isGuest = true;
			state.isAdmin = false;
			state.permissions = [];
		},
		setAdminStatus(state, action) {
			state.isAdmin = action.payload;
		},
		setPermissions(state, action: PayloadAction<string[]>) {
			state.permissions = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(loginUsingToken.pending, setLoadingState)
			.addCase(loginUsingToken.fulfilled, (state, { payload }) => {
				state.isRequest = false;
				state.isAuth = true;
				state.loading = false;
				state.error = null;
				state.user = payload.data;
			})

			.addCase(loginAsGuest.pending, setLoadingState)
			.addCase(loginAsGuest.fulfilled, (state) => {
				state.loading = false;
				state.isAuth = true;
				state.error = null;
				state.user = guestUser;
				state.isGuest = true;
				state.isAdmin = false;
				state.permissions = [];
			})

			.addCase(logout.pending, setLoadingState)
			.addCase(logout.fulfilled, (state) => {
				state.loading = false;
				state.isAuth = false;
				state.user = {} as User;
				state.isAdmin = false;
			})

			.addCase(checkAuth.pending, setLoadingState)
			.addCase(checkAuth.fulfilled, (state, { payload }) => ({
				...state,
				loading: false,
				isAuth: true,
				user: payload.data,
				isAdmin: state.isAdmin,
			}))

			.addCase(updateCurrentUser.pending, setLoadingState)
			.addCase(updateCurrentUser.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})
			.addCase(fetchAndSetAdminPermissions.fulfilled, (state, { payload }) => {
				if (payload) {
					state.isAdmin = payload.isAdmin;
					state.permissions = payload.permissions;
				} else {
					state.isAdmin = false;
					state.permissions = [];
				}
			})

			.addMatcher(isAnyOf(checkAuth.rejected), (state, action) => {
				console.warn('[AUTH] Not authenticated (Error: %o)', action.payload);
				state.loading = false;
				state.isAuth = false;
				state.user = {} as User;
				state.isAdmin = false;
				state.permissions = [];
			})

			.addMatcher(
				isAnyOf(updateCurrentUser.rejected, loginUsingToken.rejected),
				(state, action) => {
					console.error('[AUTH] %o error: %o', action.type, action.payload);
					state.error = action.payload ?? rejectionError;
					state.loading = false;
					state.isRequest = false;
				}
			)

			.addMatcher(
				isAnyOf(fetchAndSetAdminPermissions.rejected),
				(state, action) => {
					console.error('Failed to fetch admin status:', action.payload);
					state.isAdmin = false;
				}
			);
	},
});

const setLoadingState = (state: AuthState) => {
	state.loading = true;
	state.error = null;
};

export const authState = (state: RootState) => state.auth;
export const { updateOnlineStatus, guestMode, setAdminStatus, setPermissions } =
	authSlice.actions;

export default authSlice.reducer;
