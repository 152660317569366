import { FC } from 'react';
import style from 'assets/styles/components.module.scss';
import { Tag, Spin } from 'antd';

type Props = (PropsWithValues | PropsWithChildren) & {
	label?: string;
	isLoading?: boolean;
	noTagsMessage?: string;
};
interface PropsWithChildren extends ChildrenProps {
	values?: undefined;
}
interface PropsWithValues {
	values: string[];
	children?: undefined;
}

export const TagField: FC<Props> = (props) => {
	return (
		<div className={style.inputFieldWrapper} style={{ marginBottom: '1.5rem' }}>
			<span className={style.inputFieldLabel}>{props.label}</span>
			<div className={style.tagWrapper}>
				<Spin spinning={props.isLoading} size="small">
					{props.isLoading ? null : props.values && props.values.length > 0 ? (
						props.values.map((v) => (
							<Tag color="var(--primary-dark)" key={v}>
								{v}
							</Tag>
						))
					) : (
						<span className={style.noTagsMessage}>
							{props.noTagsMessage || 'No tags available'}
						</span>
					)}
				</Spin>
			</div>
		</div>
	);
};
