import React from 'react';

import { Plus } from 'assets/icons/svg';
import style from 'assets/styles/components.module.scss';

interface ButtonProps {
	onClick: (e: React.MouseEvent<HTMLElement>) => void;
	label?: string | undefined;
	disabled?: boolean;
}

export const AddButton: React.FC<ButtonProps> = ({
	onClick,
	label,
	disabled,
}) => {
	return (
		<button className={style.addButton} onClick={onClick} disabled={disabled}>
			<Plus /> {label ? label : 'Add'}
		</button>
	);
};
