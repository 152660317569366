import React, { Dispatch, useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { LinkInputIcon, StarIcon } from 'assets/icons/svg';
import style from 'assets/styles/editAddElementForm.module.scss';
import {
	InputDate,
	InputField,
	InputTextarea,
	PictureField,
} from 'components/fields';
import { SelectField } from 'components/selects';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
	addNews,
	deleteNewsPicture,
	oneNewsState,
	updateNews,
} from 'store/slices/oneNews';
import { newsSchema } from 'validations/FormValidation';
import { AddNewsDto, EntityStatus, NewsDto, NewsCategory } from 'api';
import { NewsCategories, NewsStatus, NewsStatuses } from 'types/api';
import { NewsCategoryOptions } from 'types/news';
import { generateDisplayErrorMessage, getRequestError } from 'utils/errors';
import { App } from 'antd';
import {
	errorNotification,
	saveSuccessNotification,
} from 'utils/Notifications';
import { useQueryClient } from '@tanstack/react-query';
import { authState } from 'store/slices/auth';
import { hasWritePermission } from 'utils/permissions';
import { McButton } from 'components/mc';

interface EditNewsFormProps {
	onHide: Dispatch<boolean>;
	setRefresh: Dispatcher;
	newItem: boolean;
}

interface FormValues {
	title: string;
	description: string;
	link: string;
	date: string;
}

export const EditNewsForm: React.FC<EditNewsFormProps> = ({
	newItem,
	setRefresh,
	onHide,
}) => {
	const {
		formState: { errors },
		handleSubmit,
		control,
		setValue,
		setError: setFormError,
	} = useForm<FormValues>({
		mode: 'onBlur',
		resolver: yupResolver(newsSchema),
		defaultValues: {
			link: '',
			description: '',
			title: '',
			date: '',
		},
	});

	const dispatch = useAppDispatch();
	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'news');

	const { news: currNews } = useAppSelector(oneNewsState);
	const newsWithFullSizeImg = useMemo(
		() => (newItem ? ({} as NewsDto) : currNews),
		[newItem, currNews]
	);
	const [status, setStatus] = useState<EntityStatus | undefined>(
		newsWithFullSizeImg.entityStatus
	);
	const [category, setCategory] = useState<NewsCategory>(NewsCategories.NEWS);

	const [selectedImage, setSelectedImage] = useState<Blob | undefined>();
	const [compressedFile, setCompressedFile] = useState<Blob | undefined>();
	const [isImageDeleted, setIsImageDeleted] = useState(false);
	const [curNews, setCurNews] = useState({
		extraNew: newsWithFullSizeImg.extraNew,
	});
	const { notification } = App.useApp();
	const queryClient = useQueryClient();

	useEffect(() => {
		if (newsWithFullSizeImg) {
			setValue('title', newsWithFullSizeImg.title ?? '', {
				shouldValidate: true,
				shouldDirty: false,
			});
			setValue('link', newsWithFullSizeImg.link ?? '', {
				shouldValidate: true,
				shouldDirty: false,
			});
			setValue('description', newsWithFullSizeImg.description ?? '', {
				shouldValidate: true,
				shouldDirty: false,
			});
			setCategory(newsWithFullSizeImg.category ?? NewsCategories.NEWS);
			setValue('date', newsWithFullSizeImg.date ?? '', {
				shouldValidate: true,
				shouldDirty: false,
			});

			setCurNews((prev) => ({
				...prev,
				extraNew: newsWithFullSizeImg.extraNew,
			}));
			setStatus(newsWithFullSizeImg.entityStatus);
		}
	}, [newsWithFullSizeImg, newItem, setValue]);

	const updateNewsHandler: SubmitHandler<FormValues> = (data) => {
		newItem ? saveNewItem(data) : updateItem(data);
	};

	const saveNewItem = (data: FormValues) => {
		const newsDto: AddNewsDto = {
			title: data.title.trim(),
			description: data.description.trim(),
			date: data.date,
			category: category,
			extraNew: curNews.extraNew,
			entityStatus: status,
		};

		dispatch(addNews([newsDto, selectedImage, compressedFile]))
			.unwrap()
			.then(
				() => {
					onHide(true);
					notification.success(saveSuccessNotification(data.title.trim()));
					queryClient.invalidateQueries({ queryKey: ['news'] });
					setRefresh((prev) => !prev);
				},
				(error) => {
					const errorDto = getRequestError(error);
					if (
						errorDto.code === 'ENTITY_UNIQUE_CONFLICT' &&
						errorDto.target === 'title'
					)
						setFormError(errorDto.target, {
							type: 'custom',
							message: 'Another news item with the same title already exist!',
						});
					notification.error(
						errorNotification(generateDisplayErrorMessage(errorDto))
					);
				}
			);
	};
	const updateItem = (data: FormValues) => {
		const titleChanged = data.title.trim() !== newsWithFullSizeImg.title;
		const linkChanged = data.link.trim() !== newsWithFullSizeImg.link;
		const descChanged =
			data.description.trim() !== newsWithFullSizeImg.description;
		const imgChanged = selectedImage instanceof File;
		const extraChanged = curNews.extraNew !== newsWithFullSizeImg.extraNew;
		const statusChanged =
			status !== (newsWithFullSizeImg.entityStatus as string);
		const categoryChanged =
			category !== (newsWithFullSizeImg.category as string);
		const dateChanged = data.date !== newsWithFullSizeImg.date;

		if (
			titleChanged ||
			descChanged ||
			imgChanged ||
			isImageDeleted ||
			extraChanged ||
			statusChanged ||
			linkChanged ||
			dateChanged ||
			categoryChanged
		) {
			const newsDto = {
				title: titleChanged ? data.title.trim() : undefined,
				description: descChanged ? data.description.trim() : undefined,
				extraNew: extraChanged ? curNews.extraNew : undefined,
				entityStatus: (statusChanged ? status : undefined) as NewsStatus,
				date: dateChanged ? data.date : undefined,
				status: statusChanged ? status : undefined,
				link: linkChanged ? data.link.trim() : undefined,
				category: categoryChanged ? category : undefined,
			};

			dispatch(
				updateNews([
					newsWithFullSizeImg.id as number,
					newsDto,
					imgChanged ? selectedImage : undefined,
					imgChanged ? compressedFile : undefined,
				])
			)
				.unwrap()
				.then(
					() => {
						if (isImageDeleted) {
							dispatch(
								deleteNewsPicture({ id: newsWithFullSizeImg.id as number })
							).finally(() => {
								setRefresh((prev) => !prev);
								setIsImageDeleted(false);
							});
						} else {
							setRefresh((prev) => !prev);
						}
						notification.success(saveSuccessNotification(data.title.trim()));
						queryClient.invalidateQueries({ queryKey: ['news'] });
						onHide(true);
					},
					(error) => {
						const errorDto = getRequestError(error);
						if (
							errorDto.code === 'ENTITY_UNIQUE_CONFLICT' &&
							errorDto.target === 'title'
						)
							setFormError(errorDto.target, {
								type: 'custom',
								message: 'Another news item with the same title already exist!',
							});
						notification.error(
							errorNotification(generateDisplayErrorMessage(errorDto))
						);
					}
				);
		} else {
			onHide(false);
		}
	};

	useEffect(() => {
		if (status === NewsStatuses.DRAFT) {
			setCurNews((prev) => ({
				...prev,
				extraNew: false,
			}));
		}
	}, [status, setStatus]);

	const handleExtraNews = () => {
		if (status === NewsStatuses.PUBLISHED) {
			setCurNews((prev) => ({
				...prev,
				extraNew: !curNews.extraNew,
			}));
		}
	};

	const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		onHide(false);
	};

	return (
		<form
			className={style.addFormWrapper}
			onSubmit={handleSubmit(updateNewsHandler)}
		>
			<PictureField
				selectedImage={selectedImage}
				setSelectedImage={setSelectedImage}
				setCompressedFile={setCompressedFile}
				needToCompress={true}
				existingImagePath={newsWithFullSizeImg.fullSizeImagePath}
				isImageDeleted={isImageDeleted}
				setIsImageDeleted={setIsImageDeleted}
				isAvatar={false}
			/>
			<div className={style.editForm} style={{ marginTop: '1.5rem' }}>
				<div className={style.column}>
					<Controller
						name="title"
						control={control}
						render={({ field }) => (
							<InputField
								placeholder={'News Title'}
								tabIndex={10}
								{...field}
								label={'Title'}
								error={!!errors.title}
								errorMessage={errors.title?.message}
							/>
						)}
					/>
					<SelectField
						value={category}
						options={NewsCategoryOptions}
						label={'Category'}
						setSelectedField={setCategory}
					/>
					<Controller
						name="description"
						control={control}
						render={({ field }) => (
							<InputTextarea
								{...field}
								placeholder={'Description'}
								label={'Description'}
								error={errors.description === undefined ? false : true}
								errorMessage={errors.description?.message}
							/>
						)}
					/>
				</div>
				<div className={style.column}>
					<SelectField
						defaultValue={status}
						options={[
							{ label: 'Draft', value: NewsStatuses.DRAFT },
							{ label: 'Published', value: NewsStatuses.PUBLISHED },
						]}
						label={'Status'}
						setSelectedField={setStatus}
					/>
					<Controller
						name="date"
						control={control}
						render={({ field }) => (
							<InputDate
								label={'Date'}
								value={field.value}
								setDate={(date) =>
									setValue('date', date, {
										shouldDirty: true,
										shouldTouch: true,
										shouldValidate: true,
									})
								}
								ref={field.ref}
							/>
						)}
					></Controller>
					<Controller
						name="link"
						control={control}
						render={({ field }) => (
							<InputField
								label="Link"
								icon={<LinkInputIcon />}
								placeholder="https://example.com"
								{...field}
							/>
						)}
					/>
				</div>
			</div>
			<div className={style.extraNewsField}>
				<div className={style.extraNewsTitle}>Featured News:</div>
				<div
					className={
						curNews.extraNew === true
							? style.extraNewsIcon
							: style.notExtraNewsIcon
					}
					onClick={handleExtraNews}
				>
					<StarIcon />
				</div>
			</div>
			<div className={style.buttonsWrapper}>
				<McButton onClick={cancel}>Cancel</McButton>
				<McButton primary type="submit" disabled={!canEdit}>
					Save
				</McButton>
			</div>
		</form>
	);
};
