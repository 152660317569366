import React, { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import style from 'assets/styles/editAddElementForm.module.scss';
import { InputDate, InputField, InputTextarea } from 'components/fields';
import { SelectCustomer, SelectField } from 'components/selects';
import { Controller, useForm } from 'react-hook-form';
import { editSubscriptionSchema } from 'validations/FormValidation';
import {
	AddLicenseActionDto,
	AddSubscriptionDto,
	AdminSubscriptionDto,
	EditSubscriptionDto,
	SubscriptionType,
	SubscriptionTypeVals,
} from 'api';
import { SubscriptionStatus } from 'api';
import { McButton } from 'components/mc';
import { SubscriptionStatuses } from 'types/api';
import { App, Divider, Spin } from 'antd';
import {
	errorNotification,
	mcErrorNotification,
	saveSuccessNotification,
} from 'utils/Notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import subscriptionsService from 'services/SubscriptionsService';
import { getRequestError } from 'utils/errors';
import LicenseBlock2 from './LicenseBlock2';
import {
	generateSubscriptionUpdateDto,
	hasFormChangedHelper,
	isAddAction,
	LicenseInfo,
	SubscriptionFormValues,
} from './SubscriptionUtilityFunctions';
import McSpinner from 'components/MotionCloudSpinner';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { hasWritePermission } from 'utils/permissions';

interface EditSubscriptionFormProps {
	subscription: AdminSubscriptionDto | 'new';
	hasOpenRequests?: boolean;
	hideForm: () => void;
}

export const EditSubscriptionForm: React.FC<EditSubscriptionFormProps> = ({
	subscription: subscriptionProp,
	hasOpenRequests,
	hideForm,
}) => {
	const [subscription] = useState(subscriptionProp);
	const [licenses, setLicenses] = useState<LicenseInfo[]>([]);
	const [importedExisting, setImportedExisting] = useState<boolean>(false);

	const isNew = subscription === 'new';
	const queryClient = useQueryClient();
	const {
		register,
		formState,
		control,
		handleSubmit,
		setValue,
		setError: setFormError,
	} = useForm<SubscriptionFormValues>({
		mode: 'onBlur',
		resolver: yupResolver(editSubscriptionSchema),
		defaultValues: {
			renewalDate: isNew ? undefined : subscription.renewalDate,
			subscriptionId: isNew ? undefined : subscription.subscriptionID,
			adminNotes: isNew ? undefined : subscription.adminNotes,
		},
	});

	const { notification, modal } = App.useApp();

	const [status, setStatus] = useState<SubscriptionStatus>(
		isNew ? SubscriptionStatuses.ACTIVE : SubscriptionStatuses.INACTIVE
	);

	const [seats, setSeats] = useState<number>(1);

	const [userId, setUserId] = useState<number | undefined>(undefined);

	const [licenseType, setLicenseType] = useState<SubscriptionType>(
		SubscriptionTypeVals.LOCAL
	);

	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'licenses');

	useEffect(() => {
		if (!!subscription && !isNew) {
			setValue('subscriptionId', subscription.subscriptionID ?? '', {
				shouldValidate: true,
			});
			setValue('renewalDate', subscription.renewalDate ?? '', {
				shouldValidate: true,
			});
			setValue('adminNotes', subscription.adminNotes ?? '', {
				shouldValidate: true,
			});

			if (!!subscription.ownerInfo) setUserId(subscription.ownerInfo.userId);
			if (!!subscription.status) setStatus(subscription.status);
			if (!!subscription.type) setLicenseType(subscription.type);
			if (!!subscription.seats) setSeats(subscription.seats);
		}
	}, [subscription, setValue, isNew]);

	const { mutate: addSubscription, isPending: isPendingAddSubscription } =
		useMutation({
			mutationFn: (dto: AddSubscriptionDto) =>
				subscriptionsService.createSubscription(dto),
			onSuccess: (_, data) => {
				notification.success(saveSuccessNotification(data.subscriptionID));
				queryClient.invalidateQueries({
					queryKey: ['subscriptions'],
				});
				hideForm();
			},
			onError: (error: unknown) => {
				const errorDto = getRequestError(error);
				if (errorDto.code === 'ENTITY_UNIQUE_CONFLICT')
					setFormError('subscriptionId', {
						type: 'custom',
						message: 'Subscription ID already in use!',
					});
				notification.error(
					mcErrorNotification('Error', error, 'create', 'subscripton')
				);
			},
		});

	const { mutate: updateSubscription, isPending: isPendingUpdateSubscription } =
		useMutation({
			mutationFn: ({
				id,
				version,
				editSubscriptionDto,
			}: {
				id: number;
				version: number;
				editSubscriptionDto: EditSubscriptionDto;
			}) =>
				subscriptionsService.updateSubscription(
					editSubscriptionDto,
					version,
					id
				),
			onSuccess: (_, data) => {
				notification.success(
					saveSuccessNotification(data.editSubscriptionDto.subscriptionID)
				);
				queryClient.invalidateQueries({
					queryKey: ['subscriptions'],
				});
				hideForm();
			},
			onError: (error: unknown) => {
				const errorDto = getRequestError(error);
				if (errorDto.code === 'ENTITY_UNIQUE_CONFLICT')
					setFormError('subscriptionId', {
						type: 'custom',
						message: 'Subscription ID already in use!',
					});
				notification.error(
					mcErrorNotification('Error', error, 'update', 'subscription')
				);
			},
		});

	const addSubscriptionHandler = (
		data: SubscriptionFormValues,
		licenses: LicenseInfo[] | undefined
	) => {
		let addActions: AddLicenseActionDto[] = [];

		if (!!licenses) {
			for (let license of licenses) {
				if (!license.action || !isAddAction(license.action)) {
					notification.error(
						errorNotification('License actions must be add for create form!')
					);
					return;
				}

				addActions.push(license.action);
			}
		}

		if (!userId) {
			notification.error(errorNotification('User id needs to be selected!'));
			return;
		}

		const addSubscriptionDto: AddSubscriptionDto = {
			userId: userId,
			status: status,
			renewalDate: data.renewalDate,
			subscriptionID: data.subscriptionId,
			seats: licenseType === SubscriptionTypeVals.NETWORK ? seats : 1,
			type: licenseType,
			adminNotes: data.adminNotes,
			addLicenseActions: addActions,
		};

		addSubscription(addSubscriptionDto);
	};

	const updateSubscriptionHandler = (
		data: SubscriptionFormValues,
		subscription: AdminSubscriptionDto,
		licenses: LicenseInfo[]
	) => {
		if (!userId) {
			notification.error(errorNotification('User id needs to be selected!'));
			return;
		}

		const updateDto = generateSubscriptionUpdateDto(
			subscription,
			licenses,
			formState,
			status,
			licenseType,
			seats,
			data,
			userId
		);

		if (!updateDto) return;

		updateSubscription({
			id: subscription.id,
			version: subscription.version,
			editSubscriptionDto: updateDto,
		});
	};

	const hasChanges = useMemo(() => {
		if (isNew) return true;
		return hasFormChangedHelper(
			subscription,
			licenses,
			formState,
			status,
			licenseType,
			seats,
			userId ?? -1
		).hasChanged;
	}, [
		formState,
		isNew,
		licenseType,
		licenses,
		seats,
		status,
		subscription,
		userId,
	]);

	const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		hideForm();
	};

	return (
		<>
			<Spin
				spinning={isPendingAddSubscription || isPendingUpdateSubscription}
				indicator={<McSpinner />}
				style={{ alignContent: 'center' }}
			>
				<div className={isNew ? style.addFormWrapper : style.editFormWrapper}>
					<form
						onSubmit={handleSubmit((data, e) => {
							e?.preventDefault();
							modal.confirm({
								title: 'Send in form?',
								content:
									'You are about to send in the subscription form. All changes will be applied together or not at all if any were to fail!',
								centered: true,
								maskClosable: false,
								onOk: () => {
									isNew
										? addSubscriptionHandler(data, licenses)
										: updateSubscriptionHandler(data, subscription, licenses);
								},
							});
						})}
					>
						<div className={style.editForm} style={{ flexDirection: 'column' }}>
							{subscription !== 'new' && (
								<div className={style.row} style={{ justifyContent: 'start' }}>
									<div style={{ color: 'var(--text-disabled)' }}>
										(eid: {subscription.id}, ver: {subscription.version})
									</div>
									{subscriptionProp !== 'new' &&
										subscription.version !== subscriptionProp.version && (
											<div style={{ color: 'var(--add-red)' }}>
												outdated (new existing version:
												{` ${subscriptionProp.version}`})
											</div>
										)}
								</div>
							)}
							<div className={style.row}>
								<div style={{ flex: 1 }}>
									<Controller
										name="subscriptionId"
										control={control}
										render={({ field }) => (
											<InputField
												placeholder={'Subscription ID'}
												{...field}
												label={`Subscription ID`}
												error={!!formState.errors.subscriptionId}
												errorMessage={formState.errors.subscriptionId?.message}
											/>
										)}
									/>
								</div>
								<div style={{ flex: 1 }}>
									<SelectField
										defaultValue={SubscriptionStatuses.ACTIVE}
										options={[
											{ label: 'Active', value: SubscriptionStatuses.ACTIVE },
											{
												label: 'Inactive',
												value: SubscriptionStatuses.INACTIVE,
											},
										]}
										label={'Status'}
										value={status}
										setSelectedField={setStatus}
									/>
								</div>
							</div>
							<div className={style.row}>
								<div style={{ flex: 4 }}>
									<Controller
										name="renewalDate"
										control={control}
										render={({ field }) => (
											<>
												<InputDate
													label={'Renewal date (UTC)'}
													{...field}
													isUtc
													error={
														formState.errors.renewalDate === undefined
															? false
															: true
													}
													errorMessage={formState.errors.renewalDate?.message}
													setDate={(date) =>
														setValue('renewalDate', date, {
															shouldValidate: true,
															shouldDirty: true,
														})
													}
													timeRemainingSuffix
													timeRemainingIncludeTarget
												/>
												<input type="hidden" {...register('renewalDate')} />
											</>
										)}
									/>
								</div>
								<div style={{ flex: 4 }}>
									<SelectCustomer
										userId={userId}
										setUserId={setUserId}
										label="Customer"
									/>
								</div>
								<div style={{ flex: 2 }}>
									<SelectField
										defaultValue={SubscriptionTypeVals.LOCAL}
										options={[
											{
												label: 'Standalone',
												value: SubscriptionTypeVals.LOCAL,
											},
											{
												label: 'Floating',
												value: SubscriptionTypeVals.NETWORK,
											},
										]}
										label={'License Type'}
										value={licenseType}
										setSelectedField={setLicenseType}
										onChange={(val) => {
											setLicenseType(val);
											if (val === SubscriptionTypeVals.LOCAL) {
												setSeats(1);
											}
										}}
									/>
								</div>

								<div style={{ flex: 2 }}>
									<InputField
										value={
											licenseType === SubscriptionTypeVals.NETWORK ? seats : 1
										}
										type="number"
										label="Number of seats"
										onChange={(newVal) => {
											if (licenseType === SubscriptionTypeVals.NETWORK) {
												const value: number =
													parseInt(newVal.target.value) ?? 1;
												setSeats(value > 1 ? value : 1);
											} else {
												setSeats(1);
											}
										}}
										disabled={licenseType === SubscriptionTypeVals.LOCAL}
									/>
								</div>
							</div>

							<div>
								<Controller
									name="adminNotes"
									control={control}
									render={({ field }) => (
										<InputTextarea
											{...field}
											placeholder={'Description'}
											label={'Admin Notes (max 1000 characters)'}
											error={
												formState.errors.adminNotes === undefined ? false : true
											}
											errorMessage={formState.errors.adminNotes?.message}
										/>
									)}
								/>
							</div>
						</div>
						<Divider />
						<div className={style.row}>
							<LicenseBlock2
								hasOpenRequests={!!hasOpenRequests}
								importedExisting={importedExisting}
								setImportedExisting={setImportedExisting}
								subscription={subscription}
								licenses={licenses}
								setLicenses={setLicenses}
							/>
						</div>
						<McButton.Row
							style={{
								justifyContent: 'flex-end',
								marginTop: '2rem',
							}}
						>
							<McButton onClick={cancel}>Cancel</McButton>
							<McButton
								primary
								type="submit"
								disabled={
									!hasChanges ||
									isPendingAddSubscription ||
									isPendingUpdateSubscription ||
									!canEdit
								}
							>
								{isNew ? 'Create' : 'Save'}
							</McButton>
						</McButton.Row>
					</form>
				</div>
			</Spin>
		</>
	);
};
