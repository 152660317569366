import { FC, useEffect, useMemo, useState } from 'react';
import { OneCustomerSubscription } from 'modules/CustomerSubscriptions/OneCustomerSubscription';
import style from 'assets/styles/licensingPage.module.scss';
import { useAppSelector } from 'hooks/hooks';
import { App, Empty, Spin } from 'antd';
import { Cond } from 'utils/Cond';
import { useParams } from 'react-router-dom';
import { McButton } from 'components/mc';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import subscriptionsService from 'services/SubscriptionsService';
import { authState } from 'store/slices/auth';
import { mcErrorNotification } from 'utils/Notifications';
import applicationsService from 'services/ApplicationsService';
import modulesService from 'services/ModulesService';
import ActivationWizard from 'modules/ActivationWizard/ActivationWizard';
import { WizardHat } from 'assets/icons/svg';
import { useDebounce } from 'hooks/useDebounce';
import { SubscriptionDto } from 'api';
import style2 from 'assets/styles/manageElements.module.scss';
import { SearchField } from 'components/fields';

export const LicensingPage: FC = () => {
	const params = useParams();
	const { notification } = App.useApp();
	const { isGuest } = useAppSelector(authState);
	const [searchText, setSearchText] = useState('');
	const debouncedSearch = useDebounce(searchText, 200);
	const PAGE_SIZE = 10;

	const {
		data: subscriptionPages,
		error: getSubscriptionError,
		isLoading: isLoadingSubscriptions,
		isFetching: isFetchingSubscriptions,
		hasNextPage,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: ['subscriptions', 'customers', 'infinite', debouncedSearch],
		queryFn: ({ pageParam: pageNr }) =>
			subscriptionsService
				.getPaginatedSubscriptions(pageNr, PAGE_SIZE, debouncedSearch)
				.then((res) => res.data),
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.pageNumber + 1;
			return nextPageNr < lastPage.allPages ? nextPageNr : undefined;
		},
		enabled: !isGuest,
	});

	const allSubscriptions: SubscriptionDto[] = useMemo(
		() =>
			!!subscriptionPages
				? subscriptionPages.pages.reduce(
						(acc, page) => [...acc, ...page.content],
						[] as SubscriptionDto[]
				  )
				: [],
		[subscriptionPages]
	);

	useEffect(() => {
		if (!getSubscriptionError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				getSubscriptionError,
				'Failed',
				'fetch subscriptions'
			)
		);
	}, [getSubscriptionError, notification]);

	const {
		data: providedSubscription,
		isLoading: isLoadingProvidedSubscription,
		error: getProvidedSubscriptionError,
	} = useQuery({
		queryKey: ['subscriptions', params.id],
		queryFn: () =>
			subscriptionsService
				.getSubscriptionById(Number(params.id))
				.then((res) => [res.data]),
		enabled: params.id !== undefined && !isGuest,
		initialData: [],
	});

	// Pre-fetching
	useQuery({
		queryKey: ['applications'],
		queryFn: () =>
			applicationsService
				.getApplicationsList(0, 50, undefined)
				.then((res) => res.data.content),
		initialData: [],
		enabled: !isGuest,
	});

	// Pre-fetching
	useQuery({
		queryKey: ['modules'],
		queryFn: () =>
			modulesService
				.getModulesList(0, 50, undefined)
				.then((res) => res.data.content),
		initialData: [],
		enabled: !isGuest,
	});

	const subscriptions =
		!!providedSubscription && providedSubscription.length > 0
			? providedSubscription
			: allSubscriptions;

	const loading = isLoadingSubscriptions || isLoadingProvidedSubscription;

	const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<
		number | null
	>(null);

	useEffect(() => {
		if (params.id !== undefined) setSelectedSubscriptionId(Number(params.id));
	}, [params.id]);

	useEffect(() => {
		if (!getProvidedSubscriptionError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				getProvidedSubscriptionError,
				'fetch',
				'provided subscription'
			)
		);
	}, [getProvidedSubscriptionError, notification]);

	const singleSubscription =
		typeof params.id !== 'undefined' &&
		!isLoadingProvidedSubscription &&
		providedSubscription.length > 0;

	const [displayActivationWizard, setDisplayActivationWizard] =
		useState<boolean>(false);

	const subscriptionCount =
		!!subscriptionPages && subscriptionPages.pages.length > 0
			? subscriptionPages.pages[0].allElements
			: 0;

	return isGuest ? (
		<>
			<div className={style.wizardModal}>
				<WizardHat />
				<div className={style.sectionText}>
					<h3>Offline activation wizard</h3>
					<p>
						Motion Cloud Desktop is not connected to your online account.
						Pressing the button starts the process to, together with another
						online computer, activate licenses in a container on this computer.
					</p>
				</div>
				<McButton primary onClick={() => setDisplayActivationWizard(true)}>
					Start Wizard
				</McButton>
			</div>
			{displayActivationWizard && (
				<ActivationWizard
					displayActivationWizard={displayActivationWizard}
					setDisplayActivationWizard={setDisplayActivationWizard}
				/>
			)}
		</>
	) : (
		<>
			<h3 className={style.title}>Subscriptions</h3>
			<Spin spinning={loading}>
				{!singleSubscription && (
					<div className={style2.searchAddFields}>
						<div style={{ flex: 'auto' }} className={style2.searchFieldWrapper}>
							<SearchField
								placeholder={'Search'}
								value={searchText}
								onChange={(e) => {
									setSearchText(e.target.value);
								}}
							/>
						</div>
					</div>
				)}

				<div className={style.subscriptionItemsContainer}>
					{subscriptions.map((subscription) => {
						return (
							<div key={subscription.id}>
								<OneCustomerSubscription
									subscription={subscription}
									setSelectedSubscriptionId={setSelectedSubscriptionId}
									selectedSubscriptionId={selectedSubscriptionId}
								/>
							</div>
						);
					})}
				</div>
				{subscriptionCount !== 0 && hasNextPage && !singleSubscription && (
					<div
						className={style2.paginationContainer}
						onClick={() => fetchNextPage()}
					>
						<McButton disabled={loading || isFetchingSubscriptions}>
							View more
						</McButton>
					</div>
				)}
				<Cond except={subscriptions.length > 0 || loading}>
					{singleSubscription ? (
						<Empty description="The linked subscription could not be found">
							<McButton.Route
								to={'/licensing'}
								style={{ display: 'inline-block' }}
							>
								List all subscriptions
							</McButton.Route>
						</Empty>
					) : (
						<Empty description="You currently have no subscriptions" />
					)}
				</Cond>
			</Spin>
		</>
	);
};
