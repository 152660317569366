import React, { useState, useEffect, useMemo } from 'react';

import { ManageOneUser } from 'modules/ManageUser/ManageOneUser';
import { SearchField } from 'components/fields';
import { FilterField } from 'components/fields';
import { AddButton } from 'components/buttons';
import { EditUserForm } from 'modules/ManageUser/EditUserForm';
import style from 'assets/styles/manageElements.module.scss';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { App, Spin } from 'antd';
import { AlertCircle } from 'assets/icons/svg';
import { useDebounce } from 'hooks/useDebounce';
import { isUserDto } from 'types/user';
import { UserStatuses, UserStatus } from 'types/api';
import { useParams } from 'react-router-dom';
import usersService from 'services/UsersService';
import { UserDto } from 'api';
import { McButton } from 'components/mc';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { errorNotification, warningNotification } from 'utils/Notifications';
import { hasWritePermission } from 'utils/permissions';

export const ManageUsers: React.FC = () => {
	const params = useParams();
	const { notification } = App.useApp();
	const [showAddUserForm, setShowAddUserForm] = useState(false);
	const [selStatus, setSelStatus] = useState<UserStatus>();
	const [searchedValue, setSearchedValue] = useState('');
	const [selectedToUpdate, setSelectedToUpdate] = useState<string | null>(null);
	const debouncedSearchTerm = useDebounce(searchedValue, 700);
	const PAGE_SIZE = 10;

	const { user: currentAdmin } = useAppSelector(authState);

	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'users');

	const {
		data: userPages,
		error: getUsersError,
		hasNextPage: hasMoreUsers,
		isLoading: isLoadingUsers,
		isFetching: isFetchingUsers,
		fetchNextPage: getNextUserPage,
	} = useInfiniteQuery({
		queryKey: ['users', 'infinite', selStatus, debouncedSearchTerm],
		queryFn: ({ pageParam: pageNr }) =>
			usersService
				.getUsersList(pageNr, PAGE_SIZE, selStatus, debouncedSearchTerm, true)
				.then((res) => res.data),
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.pageNumber + 1;
			return nextPageNr < lastPage.allPages ? nextPageNr : undefined;
		},
	});

	const users: UserDto[] = useMemo(
		() =>
			!!userPages
				? userPages.pages.reduce(
						(acc, page) => [...acc, ...page.content],
						[] as UserDto[]
				  )
				: [],
		[userPages]
	);

	const allUsers = !!userPages ? userPages.pages[0].allElements : 0;

	// If the param id is provided, select the corresponding user
	const { data: selectedUser, error: getSelectedUserError } = useQuery({
		queryKey: ['users', params.email],
		queryFn: () =>
			usersService.getUserByEmail(params.email!).then((res) => res.data),
		enabled: !!params.email,
		initialData: undefined,
	});

	useEffect(() => {
		if (!getSelectedUserError) return;
		notification.warning(warningNotification('Failed to fetch selected user!'));
	}, [getSelectedUserError, notification]);

	useEffect(() => {
		if (!getUsersError) return;
		notification.error(errorNotification('Failed to fetch user list!'));
	}, [getUsersError, notification]);

	// Should never happen, this is just to inform the type system of this
	if (!isUserDto(currentAdmin)) return <></>;

	return (
		<div className={style.wrapper}>
			<div className={style.container}>
				<h1 className={style.header}>Manage Users</h1>
				{!selectedUser && (
					<>
						<div className={style.amountInfo}>
							{`Displaying ${users.length} out of ${allUsers} users`}
						</div>
						<div className={style.searchAddFields}>
							<div
								style={{ flex: 'auto' }}
								className={style.searchFieldWrapper}
							>
								<SearchField
									placeholder={'Search'}
									value={searchedValue}
									onChange={(e) => {
										setSearchedValue(e.target.value);
									}}
								/>
							</div>
							<div style={{ flex: '0 0 13.125rem' }}>
								<FilterField
									label={'Status: '}
									selValue={selStatus}
									values={[
										undefined,
										UserStatuses.REVIEWING,
										UserStatuses.PENDING,
										UserStatuses.VERIFIED,
										UserStatuses.ACTIVE,
										UserStatuses.INACTIVE,
									]}
									labels={[
										'Show All',
										'Reviewing',
										'Pending',
										'Verified',
										'Active',
										'Inactive',
									]}
									admin={true}
									setSelectedField={setSelStatus}
								/>
							</div>
							<AddButton
								onClick={() => setShowAddUserForm((prev) => !prev)}
								disabled={!canEdit}
							/>
						</div>
					</>
				)}
				<div style={{ marginTop: '2rem' }}>
					{showAddUserForm && (
						<EditUserForm user="new" onHide={() => setShowAddUserForm(false)} />
					)}
				</div>
				{allUsers > 0 && !selectedUser ? (
					<h3 className={style.listTitle}>User List</h3>
				) : (
					<></>
				)}
				<div>
					<Spin spinning={isLoadingUsers} size="large">
						{allUsers === 0 && !selectedUser ? (
							<div className={style.noElementsInfo}>
								There are no Users to show here
								<AlertCircle />
							</div>
						) : (
							<></>
						)}
						<div className={style.itemsContainer}>
							{selectedUser ? (
								<ManageOneUser
									user={selectedUser}
									isCurAdmin={currentAdmin.email === selectedUser.email}
									setSelectedToUpdate={setSelectedToUpdate}
									selectedToUpdate={selectedUser.email}
								/>
							) : (
								users.map((user) => {
									return (
										<div key={user.email}>
											<ManageOneUser
												user={user}
												isCurAdmin={currentAdmin.email === user.email}
												setSelectedToUpdate={setSelectedToUpdate}
												selectedToUpdate={selectedToUpdate}
											/>
										</div>
									);
								})
							)}
						</div>
					</Spin>
				</div>
				{hasMoreUsers && !selectedUser && (
					<div
						className={style.paginationContainer}
						onClick={() => {
							getNextUserPage();
						}}
					>
						<McButton disabled={isLoadingUsers || isFetchingUsers}>
							View more
						</McButton>
					</div>
				)}
			</div>
		</div>
	);
};
