import { IconEmptyCube } from 'assets/icons/IconEmptyCube';
import { FC, ReactNode, useEffect, useMemo } from 'react';
import { FileSource, ipc, isDesktop } from 'desktop';
import { EngineOption } from 'components/selects';
import { App, Tooltip } from 'antd';
import { errorNotification, warningNotification } from 'utils/Notifications';
import McCard from './McCard';
import style from 'assets/styles/RecentFileCard.module.scss';
import prettyBytes from 'pretty-bytes';
import { McMoreMenu } from 'components/mc';
import { useQuery } from '@tanstack/react-query';
import { invoke } from '@tauri-apps/api';
import { CMLicense } from 'types/codemeter';
import { isAfter, parseISO } from 'date-fns';
import { localApplications as allLocalApplications } from 'desktop/LocalApplications';

interface CardProps {
	selectedEngine: EngineOption | undefined;
	recentFile: {
		image: ReactNode;
		name: string;
		timeStamp: string;
		fileSource: FileSource;
		size?: number;
		file_path?: string;
		folder_path?: string;
		age?: number;
		created?: string;
		app_id?: string;
	};
}

export const RecentFileCard: FC<CardProps> = ({
	recentFile,
	selectedEngine,
}) => {
	const { notification } = App.useApp();
	const isDisabled = !selectedEngine || !selectedEngine.installed;

	const openSessionFileHandler = (
		engineName: string,
		fileName: string,
		fileSource: FileSource,
		startWithApplication?: string
	) => {
		ipc
			.openSessionFile(engineName, fileName, fileSource, startWithApplication)
			.catch((err) => {
				console.error('Session file failed to launch: %o', err);
				notification.error(errorNotification('Failed to launch session file!'));
			});
	};

	const openExplorerLocation = (folderPath: string) => {
		ipc
			.showDirectoryInShell(folderPath)
			.catch((err) => {
				console.error('Failed to open session file directory: %o', err);
				notification.error(
					errorNotification('Failed open session file location!')
				);
			})
			.catch((e) => {
				console.error('Failed to show directory!');
			});
	};

	const { data: localProductCodes, error: getCodemeterLicensesError } =
		useQuery({
			queryKey: ['local', 'products', 'codes'],
			queryFn: () =>
				invoke('get_codemeter_licenses', {
					firmCode: 6000157,
				}).then((res) => {
					var licenses = res as CMLicense[];
					if (!Array.isArray(licenses)) {
						licenses = [] as CMLicense[];
					}
					const now: Date = new Date();
					const expired: Number[] = [];
					const productCodes = licenses
						.filter((l) => {
							let expirationFilter: boolean = true;
							if (
								l.expiration_time !== undefined &&
								l.expiration_time !== null
							) {
								const expiration: Date = parseISO(l.expiration_time);
								if (!isAfter(expiration, now)) {
									expired.push(l.product.code);
								}
								expirationFilter = isAfter(expiration, now);
							}
							return (
								l.firm.code === 6000157 &&
								l.license_quantity > 0 &&
								expirationFilter
							);
						})
						.map((l) => l.product.code);

					return { available: productCodes, expired: expired };
				}),
			initialData: { expired: [], available: [] } as {
				expired: Number[];
				available: Number[];
			},
			enabled: isDesktop,
		});

	useEffect(() => {
		if (!getCodemeterLicensesError) return;
		notification.warning(
			warningNotification('Could not read codemeter licenses!')
		);
	}, [getCodemeterLicensesError, notification]);

	// Keys to the object are the app codes and returns true if licensed, otherwise false/undefined
	const isApplicationLicensed: { [appCode: string]: boolean } = useMemo(() => {
		const lookupMap: { [appCode: string]: boolean } = {};

		if (isDesktop) {
			const appCodes: string[] = Object.keys(allLocalApplications);
			appCodes?.forEach(
				(appCode) =>
					(lookupMap[appCode] = allLocalApplications[
						appCode
					].requiredModuleProductCodes.every((prodCode) =>
						localProductCodes.available.includes(prodCode)
					))
			);
		}

		return lookupMap;
	}, [localProductCodes.available]);

	const openWithOptions = useMemo(() => {
		if (!selectedEngine || !selectedEngine.installed || !isDesktop)
			return <></>;

		return selectedEngine.installed.localApplications.map((localApp) => (
			<button
				disabled={!isApplicationLicensed[localApp.code]}
				key={localApp.code}
				className={style.extraOpenWith}
				onClick={() => {
					if (
						!selectedEngine ||
						!selectedEngine.installed ||
						!isApplicationLicensed[localApp.code]
					)
						return;
					ipc
						.openSessionFile(
							selectedEngine.installed.name,
							recentFile.name,
							recentFile.fileSource,
							localApp.code
						)
						.catch((err) => {
							console.error('Session file failed to launch: %o', err);
							notification.error(
								errorNotification('Failed to launch session file!')
							);
						});
				}}
			>
				<p>Open with</p>
				<div
					style={{
						width: '24px',
						height: '24px',
						opacity: isApplicationLicensed[localApp.code] ? 1 : 0.4,
					}}
				>
					{<localApp.icon />}
				</div>
			</button>
		));
	}, [
		isApplicationLicensed,
		notification,
		recentFile.fileSource,
		recentFile.name,
		selectedEngine,
	]);

	return (
		<McCard
			hoverable={!isDisabled}
			bordered={!isDisabled}
			size="small"
			extra={
				<div
					className={style.extraButton}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}
				>
					<McMoreMenu placement="topLeft">
						<div
							onClick={() => {
								if (!!recentFile.folder_path) {
									openExplorerLocation(recentFile.folder_path);
								}
							}}
						>
							Show file in explorer
						</div>
						{openWithOptions}
					</McMoreMenu>
				</div>
			}
			className={isDisabled ? style.disabled : ''}
			onClick={() => {
				if (!!selectedEngine && !!selectedEngine.installed) {
					openSessionFileHandler(
						selectedEngine.installed.name,
						recentFile.name,
						recentFile.fileSource
					);
				}
			}}
			title={
				<div className={style.cardTitle}>
					<div
						style={{
							display: 'inline-flex',
							opacity: isDisabled ? '0.5' : '1.0',
						}}
					>
						{recentFile.image === null ? (
							<IconEmptyCube size={24} />
						) : (
							recentFile.image
						)}
					</div>
					<Tooltip title={recentFile.name} mouseEnterDelay={1}>
						<div
							style={{ overflowX: 'hidden', textOverflow: 'ellipsis' }}
							className={isDisabled ? style.disabled : ''}
						>
							{recentFile.name}
						</div>
					</Tooltip>
				</div>
			}
		>
			<p className={style.cardInfo}>
				File size:&nbsp;
				{!!recentFile.size ? prettyBytes(recentFile.size) : 'Unknown'}
			</p>
			<p className={style.cardInfo}>Modified:&nbsp;{recentFile.timeStamp}</p>
			<p className={style.cardInfo}>Created:&nbsp;{recentFile.created}</p>
			<Tooltip title={recentFile.file_path} mouseEnterDelay={0.5}>
				<p className={style.cardInfo}>Path:&nbsp;{recentFile.file_path}</p>
			</Tooltip>
		</McCard>
	);
};
