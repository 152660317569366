import React, { useEffect, Dispatch, SetStateAction } from 'react';

import { InputField } from 'components/fields';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { editModuleSchema } from 'validations/FormValidation';
import style from 'assets/styles/editAddElementForm.module.scss';
import { ErrorDTO, ModuleItemDto, ModuleSaveDto } from 'api';
import { generateDisplayErrorMessage, getRequestError } from 'utils/errors';
import { App } from 'antd';
import {
	errorNotification,
	mcErrorNotification,
	saveSuccessNotification,
} from 'utils/Notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import modulesService from 'services/ModulesService';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { hasWritePermission } from 'utils/permissions';
import { McButton } from 'components/mc';

interface EditModuleFormProps {
	module: ModuleItemDto | 'new';
	setSelectedToUpdate?: Dispatch<SetStateAction<number | null>>;
	setShowAddModuleForm?: Dispatch<SetStateAction<boolean>>;
}

interface FormValues {
	editName: string;
	editProductCode: number;
}

export const EditModuleForm: React.FC<EditModuleFormProps> = ({
	module,
	setSelectedToUpdate,
	setShowAddModuleForm,
}) => {
	const {
		formState: { errors },
		handleSubmit,
		control,
		setValue,
		setError: setFormError,
	} = useForm<FormValues>({
		mode: 'onBlur',
		resolver: yupResolver(editModuleSchema),
		defaultValues: {
			editName: undefined,
			editProductCode: undefined,
		},
	});

	const isNew = module === 'new';
	const { notification } = App.useApp();
	const queryClient = useQueryClient();

	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'modules');

	useEffect(() => {
		if (!!module && !isNew) {
			setValue('editName', module.name, {
				shouldValidate: true,
				shouldDirty: true,
			});
			setValue('editProductCode', module.productCode ?? 0, {
				shouldValidate: true,
				shouldDirty: true,
			});
		}
	}, [isNew, module, setValue]);

	const { mutate: updateModule } = useMutation({
		mutationFn: ({ id, moduleDto }: { id: number; moduleDto: ModuleSaveDto }) =>
			modulesService.updateModuleItem(moduleDto, id),
		onSuccess: (_, data) => {
			notification.success(saveSuccessNotification(data.moduleDto.name));
			queryClient.invalidateQueries({ queryKey: ['modules'] });
			setSelectedToUpdate?.(null);
		},
		onError: (error: unknown) => {
			const errorDto: ErrorDTO = getRequestError(error);
			if (
				errorDto.code === 'ENTITY_UNIQUE_CONFLICT' &&
				errorDto.target === 'name'
			)
				setFormError('editName', {
					type: 'custom',
					message: 'Another module with the same name already exist!',
				});

			notification.error(
				errorNotification(generateDisplayErrorMessage(errorDto))
			);
		},
	});

	const updateModuleHandler: SubmitHandler<FormValues> = (data) => {
		if (isNew) return;

		if (
			data.editName === module.name &&
			data.editProductCode === module.productCode
		) {
			setSelectedToUpdate?.(null);
			return;
		}

		const moduleDto = {
			name: data.editName === module.name ? undefined : data.editName,
			productCode:
				data.editProductCode === module.productCode ||
				data.editProductCode !== undefined
					? data.editProductCode
					: undefined,
		};

		updateModule({ id: module.id, moduleDto: moduleDto });
	};

	const { mutate: addModule } = useMutation({
		mutationFn: (data: FormValues) => {
			const moduleDto: ModuleSaveDto = {
				name: data.editName,
				productCode:
					data.editProductCode !== undefined ? data.editProductCode : undefined,
			};

			return modulesService.createModule(moduleDto);
		},
		onSuccess: (_, data) => {
			notification.success(saveSuccessNotification(data.editName));
			queryClient.invalidateQueries({ queryKey: ['modules'] });
			setShowAddModuleForm?.(false);
		},
		onError: (error: unknown) => {
			const errorDto: ErrorDTO = getRequestError(error);
			if (
				errorDto.code === 'ENTITY_UNIQUE_CONFLICT' &&
				errorDto.target === 'name'
			)
				setFormError('editName', {
					type: 'custom',
					message: 'Another module with the same name already exist!',
				});
			notification.error(
				mcErrorNotification('Error', error, 'create', 'module')
			);
		},
	});

	const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		setSelectedToUpdate?.(null);
		setShowAddModuleForm?.(false);
	};

	return (
		<form
			className={isNew ? style.addFormWrapper : style.editFormWrapper}
			onSubmit={handleSubmit((data) => {
				isNew ? addModule(data) : updateModuleHandler(data);
			})}
		>
			<div className={style.editForm}>
				<div className={style.column}>
					<Controller
						name="editName"
						control={control}
						render={({ field }) => (
							<InputField
								placeholder={'Module name'}
								tabIndex={10}
								{...field}
								type={'text'}
								label={'Module name'}
								error={!!errors.editName}
								errorMessage={errors.editName?.message}
							/>
						)}
					/>
				</div>
				<div className={style.column}>
					<Controller
						name="editProductCode"
						control={control}
						render={({ field }) => (
							<InputField
								placeholder={'Product code'}
								tabIndex={20}
								{...field}
								label={'Product code'}
								type="number"
								error={errors.editProductCode === undefined ? false : true}
								errorMessage={errors.editProductCode?.message}
							/>
						)}
					/>
				</div>
			</div>
			<div className={style.buttonsWrapper}>
				<McButton onClick={cancel}>Cancel</McButton>
				<McButton primary type="submit" disabled={!canEdit}>
					Save
				</McButton>
			</div>
		</form>
	);
};
