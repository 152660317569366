/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ErrorDTO } from '../model';
import { FullDataLicenseDto } from '../model';
/**
 * LicensesApi - axios parameter creator
 * @export
 */
export const LicensesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete License on Manage Licenses tab
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLicenseItemById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteLicenseItemById.');
            }
            const localVarPath = `/api/licenses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete licenses request file of given type by id
         * @param {number} licenseRequestId 
         * @param {string} fileType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLicenseRequestFile: async (licenseRequestId: number, fileType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licenseRequestId' is not null or undefined
            if (licenseRequestId === null || licenseRequestId === undefined) {
                throw new RequiredError('licenseRequestId','Required parameter licenseRequestId was null or undefined when calling deleteLicenseRequestFile.');
            }
            // verify required parameter 'fileType' is not null or undefined
            if (fileType === null || fileType === undefined) {
                throw new RequiredError('fileType','Required parameter fileType was null or undefined when calling deleteLicenseRequestFile.');
            }
            const localVarPath = `/api/licenses/licenseRequest/{licenseRequestId}/files/{fileType}`
                .replace(`{${"licenseRequestId"}}`, encodeURIComponent(String(licenseRequestId)))
                .replace(`{${"fileType"}}`, encodeURIComponent(String(fileType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get License entity by id
         * @param {number} licenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseById: async (licenseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licenseId' is not null or undefined
            if (licenseId === null || licenseId === undefined) {
                throw new RequiredError('licenseId','Required parameter licenseId was null or undefined when calling getLicenseById.');
            }
            const localVarPath = `/api/licenses/{licenseId}`
                .replace(`{${"licenseId"}}`, encodeURIComponent(String(licenseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a boolean indicating if there are any open license requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasAnyOpenRequests: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/licenses/requests/open`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Starts a manual cleanup process of all expired licenses and returns the number of rows updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetAllExpiredLicenses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/licenses/cleanup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Starts a manual cleanup process of a specific container
         * @param {string} containerSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetContainerExpiredLicense: async (containerSerial: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerSerial' is not null or undefined
            if (containerSerial === null || containerSerial === undefined) {
                throw new RequiredError('containerSerial','Required parameter containerSerial was null or undefined when calling resetContainerExpiredLicense.');
            }
            const localVarPath = `/api/licenses/cleanup/{containerSerial}`
                .replace(`{${"containerSerial"}}`, encodeURIComponent(String(containerSerial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LicensesApi - functional programming interface
 * @export
 */
export const LicensesApiFp = function(axiosInstance: Promise<AxiosInstance>, configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete License on Manage Licenses tab
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLicenseItemById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await LicensesApiAxiosParamCreator(configuration).deleteLicenseItemById(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Delete licenses request file of given type by id
         * @param {number} licenseRequestId 
         * @param {string} fileType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLicenseRequestFile(licenseRequestId: number, fileType: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await LicensesApiAxiosParamCreator(configuration).deleteLicenseRequestFile(licenseRequestId, fileType, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get License entity by id
         * @param {number} licenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenseById(licenseId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<FullDataLicenseDto>> {
            const localVarAxiosArgs = await LicensesApiAxiosParamCreator(configuration).getLicenseById(licenseId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Returns a boolean indicating if there are any open license requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasAnyOpenRequests(options?: AxiosRequestConfig): Promise<AxiosResponse<boolean>> {
            const localVarAxiosArgs = await LicensesApiAxiosParamCreator(configuration).hasAnyOpenRequests(options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Starts a manual cleanup process of all expired licenses and returns the number of rows updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetAllExpiredLicenses(options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            const localVarAxiosArgs = await LicensesApiAxiosParamCreator(configuration).resetAllExpiredLicenses(options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Starts a manual cleanup process of a specific container
         * @param {string} containerSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetContainerExpiredLicense(containerSerial: string, options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            const localVarAxiosArgs = await LicensesApiAxiosParamCreator(configuration).resetContainerExpiredLicense(containerSerial, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
    }
};

/**
 * LicensesApi - factory interface
 * @export
 */
export const LicensesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete License on Manage Licenses tab
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLicenseItemById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return LicensesApiFp(configuration).deleteLicenseItemById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete licenses request file of given type by id
         * @param {number} licenseRequestId 
         * @param {string} fileType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLicenseRequestFile(licenseRequestId: number, fileType: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return LicensesApiFp(configuration).deleteLicenseRequestFile(licenseRequestId, fileType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get License entity by id
         * @param {number} licenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenseById(licenseId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<FullDataLicenseDto>> {
            return LicensesApiFp(configuration).getLicenseById(licenseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a boolean indicating if there are any open license requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasAnyOpenRequests(options?: AxiosRequestConfig): Promise<AxiosResponse<boolean>> {
            return LicensesApiFp(configuration).hasAnyOpenRequests(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Starts a manual cleanup process of all expired licenses and returns the number of rows updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetAllExpiredLicenses(options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            return LicensesApiFp(configuration).resetAllExpiredLicenses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Starts a manual cleanup process of a specific container
         * @param {string} containerSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetContainerExpiredLicense(containerSerial: string, options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            return LicensesApiFp(configuration).resetContainerExpiredLicense(containerSerial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LicensesApi - object-oriented interface
 * @export
 * @class LicensesApi
 * @extends {BaseAPI}
 */
export class LicensesApi extends BaseAPI {
    /**
     * 
     * @summary Delete License on Manage Licenses tab
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensesApi
     */
    public async deleteLicenseItemById(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return LicensesApiFp(this.configuration).deleteLicenseItemById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete licenses request file of given type by id
     * @param {number} licenseRequestId 
     * @param {string} fileType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensesApi
     */
    public async deleteLicenseRequestFile(licenseRequestId: number, fileType: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return LicensesApiFp(this.configuration).deleteLicenseRequestFile(licenseRequestId, fileType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get License entity by id
     * @param {number} licenseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensesApi
     */
    public async getLicenseById(licenseId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<FullDataLicenseDto>> {
        return LicensesApiFp(this.configuration).getLicenseById(licenseId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Returns a boolean indicating if there are any open license requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensesApi
     */
    public async hasAnyOpenRequests(options?: AxiosRequestConfig) : Promise<AxiosResponse<boolean>> {
        return LicensesApiFp(this.configuration).hasAnyOpenRequests(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Starts a manual cleanup process of all expired licenses and returns the number of rows updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensesApi
     */
    public async resetAllExpiredLicenses(options?: AxiosRequestConfig) : Promise<AxiosResponse<number>> {
        return LicensesApiFp(this.configuration).resetAllExpiredLicenses(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Starts a manual cleanup process of a specific container
     * @param {string} containerSerial 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicensesApi
     */
    public async resetContainerExpiredLicense(containerSerial: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<number>> {
        return LicensesApiFp(this.configuration).resetContainerExpiredLicense(containerSerial, options).then((request) => request(this.axios, this.basePath));
    }
}
