import React, { useEffect, useMemo, useState } from 'react';
import { App, Card, Spin } from 'antd';
import style2 from 'assets/styles/newsArchive.module.scss';
import style from 'assets/styles/manageElements.module.scss';
import { NewsCategory } from 'store/slices/news';
import Meta from 'antd/es/card/Meta';
import { FilterField, SearchField } from 'components/fields';
import { Link, useNavigate } from 'react-router-dom';
import { getPublicImageURL } from 'services/ApiService';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import newsService from 'services/NewsService';
import { useDebounce } from 'hooks/useDebounce';
import { NewsDto } from 'api';
import { McButton } from 'components/mc';
import { mcErrorNotification } from 'utils/Notifications';
import { isDesktop } from 'desktop';
import defaultImage from 'assets/images/top.jpg';
import { ArrowLeftIcon } from 'assets/icons/svg';

const SortingModes = {
	BY_CREATED_AT_DESC: 'Newest first',
	BY_CREATED_AT_ASC: 'Oldest first',
	BY_ALPHABET_ASC: 'Alphabetical order',
	BY_ALPHABET_DESC: 'Reverse alphabetical order',
};

const sortingKeys = Object.keys(SortingModes) as (keyof typeof SortingModes)[];
const sortingLabels = Object.values(SortingModes);

export type SortOrder = keyof typeof SortingModes;

const NewsPage: React.FC = () => {
	let navigate = useNavigate();
	const [sortOrder, setSortOrder] = useState<SortOrder>('BY_CREATED_AT_DESC');
	const [selCategory, setSelCategory] = useState<NewsCategory | undefined>();
	const PAGE_SIZE = 9; // Defaults to 3 per row
	const [searchedText, setSearcedText] = useState<string>('');
	const debouncedSearch = useDebounce(searchedText, 700);
	const { notification } = App.useApp();
	const queryClient = useQueryClient();

	const {
		data: newsPages,
		error: getNewsError,
		hasNextPage,
		isLoading,
		isFetching,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: ['news', 'infinite', debouncedSearch, selCategory, sortOrder],
		queryFn: ({ pageParam: pageNr }) =>
			newsService
				.getNewsList(
					pageNr,
					PAGE_SIZE,
					debouncedSearch,
					'PUBLISHED',
					selCategory,
					sortOrder
				)
				.then((res) => res.data),
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.pageNumber + 1;
			return nextPageNr < lastPage.allPages ? nextPageNr : undefined;
		},
	});

	const news: NewsDto[] = useMemo(
		() =>
			!!newsPages
				? newsPages.pages.reduce(
						(acc, page) => [...acc, ...page.content],
						[] as NewsDto[]
				  )
				: [],
		[newsPages]
	);

	const allNewsCount = !!newsPages ? newsPages.pages[0].allElements : 0;

	useEffect(() => {
		if (!getNewsError) return;
		notification.warning(
			mcErrorNotification('Warning', getNewsError, 'fetch', 'news items')
		);
	}, [getNewsError, notification]);

	const handleBackBtn = () => {
		navigate(-1);
	};

	return (
		<div className={style.wrapper}>
			<div className={style.container}>
				<div className={style.headerContainer}>
					<h1 className={style.header}>News Archive</h1>
					<h4 className={style.backButton} onClick={handleBackBtn}>
						<ArrowLeftIcon />
						<span>Back</span>
					</h4>
				</div>
				<>
					<div className={style.amountInfo}>
						{`Displaying ${news.length} out of ${allNewsCount} news items`}
					</div>
					<div className={style.searchAddFields}>
						<div style={{ flex: 'auto' }} className={style.searchFieldWrapper}>
							<SearchField
								placeholder={'Search'}
								value={searchedText}
								onChange={(e) => {
									setSearcedText(e.target.value);
								}}
							/>
						</div>
						<div style={{ flex: '0 0 12.5rem' }}>
							<FilterField
								label={'Category: '}
								selValue={selCategory}
								values={[undefined, 'NEWS', 'ADMIN_NOTICE', 'SOFTWARE_RELEASE']}
								labels={['All', 'News', 'Admin notice', 'Software release']}
								setSelectedField={setSelCategory}
								admin
								font="1rem"
							/>
						</div>
						<div style={{ flex: '0 0 13.125rem' }}>
							<FilterField
								label={'Sort: '}
								font="1rem"
								selValue={sortOrder}
								values={sortingKeys}
								labels={sortingLabels}
								admin
								setSelectedField={setSortOrder}
							/>
						</div>
					</div>
				</>
				<h3 className={style.listTitle}>News list</h3>
				<div className={style2.containerNews}>
					<Spin
						spinning={isLoading}
						size={'large'}
						style={{ marginTop: '1.5rem' }}
					>
						<div className={style2.newsArticles}>
							{news.map((newsItem) => (
								<Card
									key={newsItem.id}
									hoverable
									className={style2.newsArticle}
									onMouseEnter={() =>
										queryClient.prefetchQuery({
											queryKey: ['news', newsItem.id],
											queryFn: () =>
												!!newsItem.id
													? newsService
															.getNewsItemById(newsItem.id)
															.then((res) => res.data)
													: undefined,
										})
									}
								>
									<img
										alt={newsItem.title || 'News thumbnail'}
										src={
											newsItem.thumbnailImagePath
												? getPublicImageURL(newsItem.thumbnailImagePath)
												: defaultImage
										}
										onError={(e) => {
											e.currentTarget.src = defaultImage;
										}}
										style={{
											width: '100%',
											height: '200px',
											objectFit: 'cover',
											marginBottom: '1rem',
											borderRadius: '0.75rem',
										}}
									/>
									<Meta
										title={newsItem.title}
										description={newsItem.description}
										style={{ marginBottom: '1.5rem' }}
									/>
									<div className="flex" style={{ margin: 'auto 0 0' }}>
										<Link to={`/news/${newsItem.id}`} className="mc-button">
											Read More
										</Link>
									</div>
								</Card>
							))}
							{hasNextPage && (
								<div
									style={{
										width: '100%',
										display: 'flex',
										justifyContent: 'center',
										marginTop: '1.5rem',
										marginBottom: isDesktop ? '1.5rem' : '',
									}}
									onClick={() => fetchNextPage()}
								>
									<McButton disabled={isLoading || isFetching}>
										View more
									</McButton>
								</div>
							)}
						</div>
					</Spin>
				</div>
			</div>
		</div>
	);
};

const ExportedNewsPage = NewsPage;
export { ExportedNewsPage as NewsPage };
