import React, { useState, useEffect } from 'react';
import Icon from '@ant-design/icons';
import style from 'assets/styles/supportSidebar.module.scss';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import {
	SidebarOpenCollapseIcon,
	SidebarCollapseIcon,
	EnginesIcon,
} from 'assets/icons/svg';
import { SupportCategory, supportCategories } from './categories';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';

const { Sider } = Layout;

const siderStyle: React.CSSProperties = {
	backgroundColor: 'transparent',
	marginLeft: '0px',
};

interface Props {
	selected: SupportCategory;
}

const SupportSidebar: React.FC<Props> = (props) => {
	const [collapsed, setCollapsed] = useState(window.innerWidth < 1024);

	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};

	useEffect(() => {
		const handleResize = () => {
			setCollapsed(window.innerWidth < 1024);
		};

		// Initial check
		handleResize();

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<Sider
			theme="dark"
			breakpoint="xxl"
			collapsedWidth="78"
			width={242}
			className={style.supportSidebar}
			style={siderStyle}
			collapsed={collapsed}
		>
			<div
				onClick={toggleCollapsed}
				className={style.supportSidebarToggleButton}
			>
				<Icon
					component={collapsed ? SidebarOpenCollapseIcon : SidebarCollapseIcon}
				/>
			</div>
			<h4 className={style.menuCategoryTitle}>Categories</h4>
			<Menu
				theme="dark"
				mode="vertical"
				selectedKeys={[props.selected]}
				items={[
					...supportCategories.map(
						(cat): MenuItemType => ({
							key: cat.key,
							label: <Link to={`/support/${cat.key}`}>{cat.label}</Link>,
							title: cat.label,
							icon: <Icon component={cat.icon} />,
						})
					),
					{
						key: 'release-notes',
						label: <Link to="/support/release-notes">Release Notes</Link>,
						title: 'Release Notes',
						icon: <Icon component={EnginesIcon} />,
					},
				]}
			/>
		</Sider>
	);
};

export default SupportSidebar;
