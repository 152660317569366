import { ItemContainer } from 'components/ItemContainer';
import style from 'assets/styles/manageElements.module.scss';
import { ContainerDto } from 'api';
import { Tooltip } from 'antd';

interface Props {
	container: ContainerDto;
}

const ManageOneContainer = ({ container }: Props) => {
	const type =
		container.containerType === 'DONGLE'
			? 'Dongle'
			: container.containerType === 'SOFTWARE'
			? 'Software'
			: '';

	return (
		<ItemContainer>
			<div className={style.textItem} style={{ flex: 4 }}>
				<span>{container.containerSerial}</span>
			</div>
			<div className={style.textItem} style={{ flex: 3 }}>
				<span>{type}</span>
			</div>
			<div className={style.textItem} style={{ flex: 3 }}>
				{!!container.activatedByUser && (
					<Tooltip title={container.activatedByUser.email}>
						<span>
							{`${container.activatedByUser.firstName} ${container.activatedByUser.lastName}`}
						</span>
					</Tooltip>
				)}
			</div>
			<div className={style.textItem} style={{ flex: 4 }}>
				<span>Created: {container.createdAt}</span>
			</div>
		</ItemContainer>
	);
};

export default ManageOneContainer;
