import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';

import style from 'assets/styles/manageElements.module.scss';
import { SearchField } from 'components/fields';
import { AddButton } from 'components/buttons';
import { AddTemplateForm } from 'modules/ManageTemplates/AddTemplateForm';
import { Spin } from 'antd';
import { AlertCircle } from 'assets/icons/svg';
import { ManageOneTemplate } from 'modules/ManageTemplates/ManageOneTemplate';
import { useDebounce } from 'hooks/useDebounce';
import { templatesState, getTemplates } from 'store/slices/templates';
import { EditTemplateForm } from 'modules/ManageTemplates/EditTemplateForm';
import { oneTemplateState } from 'store/slices/oneTemplate';
import { FilterField } from 'components/fields';
import { TemplateDto, EntityStatus } from 'api';
import { getEditRowIndex } from 'utils/layout';
import { Cond } from 'utils/Cond';
import { McButton } from 'components/mc';
import { authState } from 'store/slices/auth';
import { hasWritePermission } from 'utils/permissions';

type TemplateStatus = EntityStatus | undefined;

export const ManageTemplates: React.FC = () => {
	const dispatch = useAppDispatch();

	const [templateForSearch, setTemplateForSearch] = useState('');
	const [selStatus, setSelStatus] = useState<TemplateStatus>();
	const [pageSize, setPageSize] = useState(12);
	const [showAddTemplateForm, setShowAddTemplateForm] = useState(false);
	const [selectedToUpdate, setSelectedToUpdate] = useState<number | null>(null);
	const [indexTemplateForEdit, setIndexTemplateForEdit] = useState<
		number | null
	>(null);
	const [refresh, setRefresh] = useState(false);
	const [searchedValue, setSearchedValue] = useState('');
	const debouncedSearchTerm = useDebounce(templateForSearch, 700);

	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'templates');

	useEffect(() => {
		if (debouncedSearchTerm) {
			setSearchedValue(debouncedSearchTerm);
		} else {
			setSearchedValue(debouncedSearchTerm);
		}
	}, [debouncedSearchTerm]);
	const { template: templateWithFullSizeImg } =
		useAppSelector(oneTemplateState);
	const { templates, loading } = useAppSelector(templatesState);

	useEffect(() => {
		dispatch(getTemplates([0, pageSize, searchedValue, selStatus]));
	}, [
		dispatch,
		searchedValue,
		refresh,
		setRefresh,
		pageSize,
		selectedToUpdate,
		selStatus,
	]);

	const [showViewMore, setShowViewMore] = useState(
		templates.allElements > pageSize
	);

	useEffect(() => {
		setShowViewMore(templates.allElements > pageSize);
	}, [pageSize, templates.allElements]);

	const editRowIndex = useMemo(
		() => getEditRowIndex(3, indexTemplateForEdit, templates?.content?.length),
		[indexTemplateForEdit, templates]
	);

	return (
		<>
			<div className={style.containerManageNews}>
				<h1 className={style.header}>Manage Factory Templates</h1>
				{!loading &&
				searchedValue === '' &&
				selStatus === undefined &&
				templates.allElements === 0 ? (
					<>
						<div
							className={style.noElementsInfo}
							style={{ marginTop: '2rem', marginBottom: '1.5rem' }}
						>
							There are no Factory Templates to show here
							<AlertCircle />
						</div>
						<AddButton
							onClick={() => setShowAddTemplateForm((prev) => !prev)}
							disabled={!canEdit}
						/>
					</>
				) : (
					<>
						<div className={style.amountInfo}>
							{templates.allElements}
							{templates.allElements > 1 ? ` Templates` : ` Template`}
						</div>
						<div className={style.searchAddFields}>
							<div style={{ flex: '1' }} className={style.searchFieldWrapper}>
								<SearchField
									placeholder={'Search'}
									value={templateForSearch}
									onChange={(e) => {
										setTemplateForSearch(e.target.value);
									}}
								/>
							</div>
							<div style={{ flex: '0 0 10.3125rem' }}>
								<FilterField
									label={'Status: '}
									selValue={selStatus}
									values={[undefined, 'DRAFT', 'PUBLISHED']}
									labels={['All', 'Drafts', 'Published']}
									admin={true}
									setSelectedField={setSelStatus}
								/>
							</div>
							<AddButton
								onClick={() => setShowAddTemplateForm((prev) => !prev)}
								disabled={!canEdit}
							/>
						</div>
					</>
				)}

				<div style={{ marginTop: '2rem' }}>
					{showAddTemplateForm && (
						<AddTemplateForm
							setShowAddTemplateForm={setShowAddTemplateForm}
							setRefresh={setRefresh}
						/>
					)}
				</div>
				{templates.allElements !== 0 ? (
					<h3 className={style.listTitle}>Template List</h3>
				) : (
					<></>
				)}
				<div>
					<Spin spinning={loading} size="large">
						<Cond
							if={
								!loading &&
								templates.allElements === 0 &&
								(searchedValue !== '' || selStatus !== undefined)
							}
						>
							<>
								<div className={style.noElementsInfo}>
									There are no Factory Templates to show here
									<AlertCircle />
								</div>
							</>
						</Cond>
						<Cond if={templates.allElements > 0}>
							<div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
								{templates.content &&
									templates.content?.map(
										(template: TemplateDto, index: number) => {
											return (
												<Fragment key={template.id}>
													<div className={style.cardWrapper}>
														<ManageOneTemplate
															template={{ ...template }}
															setRefresh={setRefresh}
															selectedToUpdate={selectedToUpdate}
															setSelectedToUpdate={setSelectedToUpdate}
															setIndexTemplateForEdit={setIndexTemplateForEdit}
															indexTemplate={index}
														/>
													</div>

													<Cond if={editRowIndex === index}>
														<div className={style.editFormWrapper}>
															{templateWithFullSizeImg &&
																selectedToUpdate ===
																	templateWithFullSizeImg.id && (
																	<EditTemplateForm
																		setSelectedToUpdate={setSelectedToUpdate}
																		setRefresh={setRefresh}
																	/>
																)}
														</div>
													</Cond>
												</Fragment>
											);
										}
									)}
							</div>
						</Cond>
					</Spin>
					{templates.allElements !== 0 && showViewMore ? (
						<div
							className={style.paginationContainer}
							onClick={() => {
								setPageSize((prev) => prev + 12);
							}}
						>
							<McButton>View more</McButton>
						</div>
					) : (
						<div></div>
					)}
				</div>
			</div>
		</>
	);
};
