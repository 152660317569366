import { McButton } from 'components/mc/McButton';
import pageStyle from './ActivationWizardPages.module.scss';
import { Dispatch, useRef, useState } from 'react';
import { CheckIcon, DownloadIcon, IconTrash } from 'assets/icons/svg';
import { ActivationWizardState } from '../ActivationWizard';
import { CmContainer, ContainerType } from 'desktop';
import { InputField } from 'components/fields';
import manualStyle from './../Components/FileButton.module.scss';
import { useMutation } from '@tanstack/react-query';
import containerService from 'services/ContainerService';
import { Spin } from 'antd';

interface Props {
	changeDisplayPage: Dispatch<ActivationWizardState>;
	setSelectedContainer: Dispatch<CmContainer | undefined>;
	selectedContainer: CmContainer | undefined;
	setContextFile: Dispatch<Blob | undefined>;
	contextFile: Blob | undefined;
}

const ImportContextPage = ({
	changeDisplayPage,
	setSelectedContainer,
	selectedContainer,
	setContextFile,
	contextFile,
}: Props) => {
	const isFileLoaded = !!contextFile;
	const [errorMessage, setErrorMessage] = useState<string | undefined>(
		undefined
	);
	const fileInputRef = useRef<HTMLInputElement>(null);

	const { mutate: importContext, isPending: isVerifyingContext } = useMutation({
		mutationFn: (file: File) =>
			containerService.getFileInfoFromContextForm(file).then((res) => res.data),
		onMutate: () => {
			setContextFile(undefined);
			setSelectedContainer(undefined);
			setErrorMessage(undefined);
		},
		onSuccess: (containerInfo, contextFile) => {
			setContextFile(contextFile);
			const codes: string[] = containerInfo.cmContainerSerial.split('-');
			let maskCode: number | undefined = undefined;
			let serialCode: number | undefined = undefined;
			let cmSerial: string | undefined = containerInfo.cmContainerSerial;
			let containerType: ContainerType | undefined = undefined;

			if (codes.length !== 2) {
				setErrorMessage('Invalid container serial!\n');
				return;
			}

			maskCode = Number(codes[0]);
			serialCode = Number(codes[1]);
			if (
				maskCode === undefined ||
				isNaN(maskCode) ||
				serialCode === undefined ||
				isNaN(serialCode)
			) {
				setErrorMessage('Invalid container serial!\n');
				return;
			}

			if (containerInfo.cmContainerType === 'DONGLE') {
				containerType = 'Dongle';
			} else if (containerInfo.cmContainerType === 'SOFTWARE') {
				containerType = 'Act';
			} else {
				setErrorMessage('Unsupported container type!\n');
				return;
			}

			setContextFile(contextFile);
			setSelectedContainer({
				mask: maskCode,
				serial: serialCode,
				identifier: cmSerial,
				container_type: containerType,
			});

			console.log({
				mask: maskCode,
				serial: serialCode,
				identifier: cmSerial,
				container_type: containerType,
			});
		},
		onError: () => {
			setContextFile(undefined);
			setSelectedContainer(undefined);
			setErrorMessage('Failed to load context file!\n');
		},
	});

	return (
		<div className={pageStyle.pageContainer}>
			<div className={pageStyle.pageContent}>
				<div className={pageStyle.pageTitle}>Import Context</div>
				<div
					style={{
						marginBottom: '8px',
						marginTop: '12px',
					}}
					className={pageStyle.divider}
				/>
				<div
					style={{
						width: '100%',
						marginBottom: '24px',
					}}
					className={pageStyle.pageText}
				>
					On the remote computer, export the context file and transfer it to
					this computer. Then import the context file by pressing "Import
					context file".
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					marginBottom: '24px',
					gap: '8px',
					alignItems: 'center',
				}}
			>
				<div className={manualStyle.fileButtonContainer}>
					<input
						className={manualStyle.fileButtonInput}
						type="file"
						id="file-input"
						style={{ display: 'none' }}
						ref={fileInputRef}
						onChange={(event) => {
							if (!isVerifyingContext) {
								const file = event.target.files![0];

								if (!file?.name.toLowerCase().endsWith('.wibucmrac')) {
									setErrorMessage(
										'Wrong file extension! (Expecting .WibuCmRaC)'
									);
									return;
								}

								try {
									importContext(file);
								} catch (e) {
									console.error('Failed to import file!');
								}
							}
						}}
					/>
					<Spin spinning={isVerifyingContext}>
						<McButton
							primary
							onClick={() => {
								if (fileInputRef !== null && fileInputRef.current !== null)
									fileInputRef.current.click();
							}}
							disabled={isVerifyingContext}
						>
							<DownloadIcon />
							Import context file
						</McButton>
					</Spin>
				</div>
				{isFileLoaded && (
					<>
						<CheckIcon />
						<div
							style={{ cursor: 'pointer' }}
							onClick={() => {
								setContextFile(undefined);
								setSelectedContainer(undefined);
								setErrorMessage(undefined);
							}}
						>
							<IconTrash />
						</div>
					</>
				)}
				{!!errorMessage && (
					<div
						style={{
							color: 'var(--add-red)',
							fontSize: '13px',
							fontWeight: '500',
							whiteSpace: 'pre-line',
						}}
					>
						{errorMessage}
					</div>
				)}
			</div>
			{!!contextFile && !!selectedContainer && (
				<>
					<InputField
						value={!!selectedContainer ? selectedContainer.identifier : ''}
						label={'Container Serial'}
						readOnly
					/>
					<InputField
						value={!!selectedContainer ? selectedContainer.container_type : ''}
						readOnly
						label={'Container Type'}
					/>
				</>
			)}
			<div
				style={{
					justifyContent: 'space-between',
				}}
				className={pageStyle.buttonBar}
			>
				<McButton
					onClick={() => {
						setContextFile(undefined);
						//setSelectedContainer(undefined); // TODO: fix candidate 1
						changeDisplayPage('OfflineActivation');
					}}
				>
					Back
				</McButton>

				<McButton
					primary
					onClick={() => {
						changeDisplayPage('DefineActivation');
					}}
					disabled={!isFileLoaded || !selectedContainer || isVerifyingContext}
				>
					Next
				</McButton>
			</div>
		</div>
	);
};

export default ImportContextPage;
