import React, { Dispatch, SetStateAction } from 'react';

import style from 'assets/styles/manageElements.module.scss';
import { IconEdit, IconTrash } from 'assets/icons/svg';
import { deleteTemplate } from 'store/slices/oneTemplate';
import { useAppDispatch } from 'hooks/hooks';
import { getOneTemplate } from 'store/slices/oneTemplate';
import { getPublicImageURL } from 'services/ApiService';
import { generateDisplayErrorMessage, getRequestError } from 'utils/errors';
import { App } from 'antd';
import {
	deleteItemModal,
	deleteSuccessNotification,
	errorNotification,
} from 'utils/Notifications';
import defaultImage from 'assets/images/top.jpg';
import { McMarkdown, McIconButton } from 'components/mc';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { hasWritePermission, hasDeletePermission } from 'utils/permissions';

interface ManageOneTemplateProps {
	template: Template;
	setRefresh: Dispatcher;
	selectedToUpdate: number | null;
	setSelectedToUpdate: Dispatch<SetStateAction<number | null>>;
	setIndexTemplateForEdit: Dispatch<SetStateAction<number | null>>;
	indexTemplate: number;
}

export const ManageOneTemplate: React.FC<ManageOneTemplateProps> = ({
	template,
	setRefresh,
	selectedToUpdate,
	setSelectedToUpdate,
	setIndexTemplateForEdit,
	indexTemplate,
}) => {
	const dispatch = useAppDispatch();
	const { modal, notification } = App.useApp();

	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'templates');
	const canDelete = hasDeletePermission(permissions, 'templates');

	const styles =
		selectedToUpdate === template.id
			? {
					border: '2px solid var(--bg-active)',
					backgroundColor: 'var(--bg-active)',
					color: 'var(--button-text)',
			  }
			: {
					color: 'var(--text-normal)',
			  };

	const stylesDescription =
		template.title!.length > 50
			? `${style.descriptionLessRows}`
			: `${style.description}`;

	const deleteTemplateHandler = () => {
		dispatch(deleteTemplate(template.id))
			.unwrap()
			.then(() => {
				setRefresh((prev) => !prev);
				notification.success(deleteSuccessNotification(template.title));
			})
			.catch((error: unknown) => {
				const errorDto = getRequestError(error);
				notification.error(
					errorNotification(generateDisplayErrorMessage(errorDto))
				);
			});
	};

	const clickEditBtnHandler = (
		e: React.MouseEvent<HTMLElement, MouseEvent>
	) => {
		e.preventDefault();

		if (selectedToUpdate === null) {
			dispatch(getOneTemplate(template.id));
			setSelectedToUpdate(template.id);
			setIndexTemplateForEdit(indexTemplate);
		} else {
			if (selectedToUpdate === template.id) {
				setSelectedToUpdate(null);
				setIndexTemplateForEdit(null);
			} else {
				dispatch(getOneTemplate(template.id));
				setSelectedToUpdate(template.id);
				setIndexTemplateForEdit(indexTemplate);
			}
		}
	};

	return (
		<div className={style.cardContainer} style={styles}>
			<div className={style.cardImgContainer}>
				<img
					alt="Template thumbnail"
					className={style.cardImage}
					src={
						template.thumbnailImagePath
							? getPublicImageURL(template.thumbnailImagePath)
							: defaultImage
					}
					onError={(e) => {
						e.currentTarget.src = defaultImage;
					}}
				/>
			</div>
			<div className={style.cardDescriptContainer}>
				<h4 className={style.cardDescriptTitle}>{template.title}</h4>
				<div className={stylesDescription}>
					<McMarkdown content={template.description} />
				</div>
			</div>
			<div
				className={`${style.cardBtnContainer} ${style.buttonsItem}`}
				style={{ gap: '0.50rem' }}
			>
				<div style={{ cursor: 'auto' }}>
					{template.entityStatus[0] +
						template.entityStatus.slice(1).toLowerCase()}
				</div>
				<div style={{ marginLeft: 'auto' }}>
					<McIconButton
						icon={<IconEdit />}
						disabled={!canEdit}
						onClick={clickEditBtnHandler}
					/>
				</div>
				<div>
					<McIconButton
						icon={<IconTrash />}
						disabled={!canDelete}
						onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
							e.preventDefault();
							modal.confirm(
								deleteItemModal(deleteTemplateHandler, template.title)
							);
						}}
					/>
				</div>
			</div>
		</div>
	);
};
