import { FC, Dispatch, SetStateAction } from 'react';

import { AppReleaseForm } from './EditAppReleaseForm';
import { ItemContainer } from 'components/ItemContainer';
import style from 'assets/styles/manageElements.module.scss';
import { IconEdit, IconTrash, MoreVertical } from 'assets/icons/svg';
import { capitalize } from 'utils';
import { ApplicationDto, ApplicationReleaseDto } from 'api';
import { getPublicImageURL } from 'services/ApiService';
import { App } from 'antd';
import {
	deleteItemModal,
	deleteSuccessNotification,
	mcErrorNotification,
} from 'utils/Notifications';
import { TemaGenericAppIcon } from 'assets/icons/apps';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import appReleasesService from 'services/AppReleasesService';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { hasWritePermission, hasDeletePermission } from 'utils/permissions';
import { McIconButton } from 'components/mc';

interface ManageOneAppProps {
	applicationRelease: ApplicationReleaseDto;
	application: ApplicationDto;
	setSelectedToUpdate: Dispatch<SetStateAction<number | null>>;
	selectedToUpdate: number | null;
}

export const ManageOneAppRelease: FC<ManageOneAppProps> = ({
	applicationRelease,
	application,
	setSelectedToUpdate,
	selectedToUpdate,
}) => {
	const { notification, modal } = App.useApp();
	const queryClient = useQueryClient();

	const styles =
		selectedToUpdate === applicationRelease.id
			? {
					backgroundColor: 'var(--bg-active)',
					color: 'var(--button-text)',
			  }
			: {
					color: 'var(--text-normal)',
			  };

	const { mutate: deleteApplicationRelease } = useMutation({
		mutationFn: (id: number) =>
			appReleasesService.deleteApplicationReleaseById(id),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['applications', 'releases'] });
			notification.success(deleteSuccessNotification());
		},
		onError: (error: unknown) =>
			notification.error(
				mcErrorNotification('Error', error, 'delete', 'application release')
			),
	});

	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'releases');
	const canDelete = hasDeletePermission(permissions, 'releases');

	return (
		<div>
			<ItemContainer styles={styles}>
				<div className={style.profileItem} style={{ width: '45%' }}>
					{!!application && !!application.iconImagePath ? (
						<img
							className={style.appAvatar}
							alt=""
							width={32}
							height={32}
							src={getPublicImageURL(application.iconImagePath)}
						/>
					) : (
						<div className={style.appAvatar}>
							<TemaGenericAppIcon size={32} />
						</div>
					)}
					<div className={style.textItem}>
						<span>{application?.name}</span>
					</div>
				</div>
				<div className={style.statusItem} style={{ width: '25%' }}>
					<div
						className={style.statusCircle}
						style={{
							background:
								applicationRelease.entityStatus === 'PUBLISHED'
									? 'var(--add-green)'
									: 'var(--add-orange)',
						}}
					></div>
					{capitalize(applicationRelease.entityStatus ?? '')}
				</div>
				<div className={style.textItem} style={{ width: '40%' }}>
					{applicationRelease.version}
				</div>
				<div className={style.buttonsItem} style={{ gap: '0.50rem' }}>
					<McIconButton
						icon={<IconEdit />}
						disabled={!canEdit}
						onClick={() =>
							setSelectedToUpdate((curr) =>
								curr === applicationRelease.id ? null : applicationRelease.id
							)
						}
					/>
					<McIconButton
						icon={<IconTrash />}
						disabled={!canDelete}
						onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
							e.preventDefault();
							modal.confirm(
								deleteItemModal(() =>
									deleteApplicationRelease(applicationRelease.id)
								)
							);
						}}
					/>
					<div style={{ cursor: 'auto' }}>
						<MoreVertical />
					</div>
				</div>
			</ItemContainer>
			{selectedToUpdate === applicationRelease.id && (
				<AppReleaseForm
					applicationRelease={applicationRelease}
					hideForm={() => setSelectedToUpdate(null)}
				/>
			)}
		</div>
	);
};
