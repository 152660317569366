import React, { useState, useEffect } from 'react';
import 'assets/styles/sidebar.scss';
import Icon from '@ant-design/icons';
import {
	AppReleasesIcon,
	EnginesIcon,
	NewsIcon,
	UsersIcon,
	TemplatesIcon,
	LicensesIcon,
	SupportIcon,
	ModulesIcon,
	ApplicationsIcon,
	StatisticsIcon,
	SidebarOpenCollapseIcon,
	SidebarCollapseIcon,
	OrganizationsIcon,
	FeatureFlagIcon,
	IconContainer,
} from 'assets/icons/svg';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import version from 'version.json';

import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import RedDot from 'components/RedDot';
import { useQuery } from '@tanstack/react-query';
import licensesService from 'services/LicenseService';
import { hasReadPermission } from 'utils/permissions';
const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const getItem = (
	label: React.ReactNode,
	key: React.Key,
	icon?: React.ReactNode,
	children?: MenuItem[],
	type?: 'group',
	disabled?: boolean
): MenuItem => {
	const itemLabel = disabled ? (
		<div className="disabledLabelStyle">{label}</div>
	) : (
		label
	);
	const itemIcon = disabled ? (
		<div className="disabledIconStyle">{icon}</div>
	) : (
		icon
	);

	return {
		label: itemLabel,
		key,
		icon: itemIcon,
		children,
		type,
		disabled,
	} as MenuItem;
};

export const Sidebar = () => {
	const { isAdmin, permissions } = useAppSelector(authState);
	const navigate = useNavigate();
	const location = useLocation();
	const [collapsed, setCollapsed] = useState(window.innerWidth < 1024);

	const isAdminTab = isAdmin && location.pathname.startsWith('/admin');

	const canViewNews = hasReadPermission(permissions, 'news');
	const canViewSupport = hasReadPermission(permissions, 'support');
	const canViewUsers = hasReadPermission(permissions, 'users');
	const canViewOrganizations = hasReadPermission(permissions, 'organizations');
	const canViewEngines = hasReadPermission(permissions, 'engines');
	const canViewAppReleases = hasReadPermission(permissions, 'releases');
	const canViewTemplates = hasReadPermission(permissions, 'templates');
	const canViewLicenses = hasReadPermission(permissions, 'licenses');
	const canViewFeatureFlags = hasReadPermission(permissions, 'featureflags');
	const canViewModules = hasReadPermission(permissions, 'modules');
	const canViewApplications = hasReadPermission(permissions, 'applications');
	const canViewStatistics = hasReadPermission(permissions, 'statistics');

	const versionMenuItem: MenuItem = {
		label: `ver: ${version.fullVersion}`,
		key: 'version',
		disabled: true,
	};

	const { data: hasOpenRequests } = useQuery({
		queryKey: ['subscriptions', 'open'],
		queryFn: () => licensesService.hasAnyOpenRequests().then((res) => res.data),
		enabled: isAdminTab,
		initialData: false,
	});

	const sideBarMenuItemsAdmin: MenuProps['items'] = [
		getItem(
			'Categories',
			'categories',
			null,
			[
				getItem(
					'News',
					'/admin/news',
					<Icon component={NewsIcon} />,
					undefined,
					undefined,
					!canViewNews
				),
				getItem(
					'Support',
					'/admin/support',
					<Icon component={SupportIcon} />,
					undefined,
					undefined,
					!canViewSupport
				),
				{ type: 'divider' },
				getItem(
					'Users',
					'/admin/users',
					<Icon component={UsersIcon} />,
					undefined,
					undefined,
					!canViewUsers
				),
				getItem(
					'Organizations',
					'/admin/organizations',
					<Icon component={OrganizationsIcon} />,
					undefined,
					undefined,
					!canViewOrganizations
				),
				{ type: 'divider' },
				getItem(
					'Engines',
					'/admin/engines',
					<Icon component={EnginesIcon} />,
					undefined,
					undefined,
					!canViewEngines
				),
				getItem(
					'App Releases',
					'/admin/releases',
					<Icon component={AppReleasesIcon} />,
					undefined,
					undefined,
					!canViewAppReleases
				),
				getItem(
					'Templates',
					'/admin/templates',
					<Icon component={TemplatesIcon} />,
					undefined,
					undefined,
					!canViewTemplates
				),
				{ type: 'divider' },
				getItem(
					'Licenses',
					'/admin/licenses',
					<>
						{hasOpenRequests && (
							<RedDot
								additionalStyle={{
									position: 'absolute',
									top: '2px',
									left: '-6px',
								}}
							/>
						)}
						<Icon component={LicensesIcon} />
					</>,
					undefined,
					undefined,
					!canViewLicenses
				),
				getItem(
					'Containers',
					'/admin/containers',
					<Icon component={IconContainer} />
				),
				{ type: 'divider' },
				getItem(
					'Feature Flags',
					'/admin/feature-flags',
					<Icon component={FeatureFlagIcon} />,
					undefined,
					undefined,
					!canViewFeatureFlags
				),
				getItem(
					'Modules',
					'/admin/modules',
					<Icon component={ModulesIcon} />,
					undefined,
					undefined,
					!canViewModules
				),
				getItem(
					'Applications',
					'/admin/applications',
					<Icon component={ApplicationsIcon} />,
					undefined,
					undefined,
					!canViewApplications
				),
				{ type: 'divider' },
				getItem(
					'Statistics',
					'/admin/statistics',
					<Icon component={StatisticsIcon} />,
					undefined,
					undefined,
					!canViewStatistics
				),
				{ type: 'divider' },
				versionMenuItem,
			],
			'group'
		),
	];

	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};

	useEffect(() => {
		const handleResize = () => {
			const isSmallScreen = window.innerWidth < 1024;
			setCollapsed(isSmallScreen);
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<>
			{isAdminTab ? (
				<Sider
					theme="dark"
					breakpoint="xxl"
					collapsedWidth="78"
					width={242}
					className="sidebar"
					collapsed={collapsed}
				>
					<div onClick={toggleCollapsed} className="sidebar-toggle-button">
						<Icon
							component={
								collapsed ? SidebarCollapseIcon : SidebarOpenCollapseIcon
							}
						/>
					</div>
					<Menu
						theme="dark"
						mode="inline"
						items={isAdminTab ? sideBarMenuItemsAdmin : undefined}
						selectedKeys={[location.pathname]}
						onSelect={({ key }) => {
							navigate(`../${key}`);
						}}
						className="sidebarMenu"
					/>
				</Sider>
			) : (
				<Sider
					theme="dark"
					width={0}
					collapsedWidth="0"
					trigger={null}
					className="without-sidebar"
				/>
			)}
		</>
	);
};
