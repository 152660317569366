import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';

import { EditEngineForm } from './EditEngineForm';
import { ItemContainer } from 'components/ItemContainer';
import style from 'assets/styles/manageElements.module.scss';
import { IconEdit, IconTrash, MoreVertical, StarIcon } from 'assets/icons/svg';
import { EngineDto } from 'api';
import { capitalize } from 'utils';
import enginesService from 'services/EnginesService';
import { App } from 'antd';
import {
	deleteItemModal,
	deleteSuccessNotification,
	mcErrorNotification,
	saveSuccessNotification,
} from 'utils/Notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { hasWritePermission, hasDeletePermission } from 'utils/permissions';
import { McIconButton } from 'components/mc';

interface ManageOneEngineProps {
	engine: EngineDto;
	setSelectedToUpdate: Dispatch<SetStateAction<number | null>>;
	selectedToUpdate: number | null;
}

export const ManageOneEngine: React.FC<ManageOneEngineProps> = ({
	engine,
	setSelectedToUpdate,
	selectedToUpdate,
}) => {
	const queryClient = useQueryClient();
	const [isFeatured, setIsFeatured] = useState(false);
	const { modal, notification } = App.useApp();

	useEffect(() => {
		if (engine.featured !== undefined) setIsFeatured(engine.featured);
	}, [engine]);

	const styles =
		selectedToUpdate === engine.id
			? {
					backgroundColor: 'var(--bg-active)',
					color: 'var(--button-text)',
			  }
			: {
					color: 'var(--text-normal)',
			  };

	const clickEditBtnHandler = (
		e: React.MouseEvent<HTMLElement, MouseEvent>
	) => {
		e.preventDefault();
		if (!engine.id) return;

		if (selectedToUpdate === null) {
			setSelectedToUpdate(engine.id);
		} else {
			if (selectedToUpdate === engine.id) {
				setSelectedToUpdate(null);
			} else {
				setSelectedToUpdate(engine.id);
			}
		}
	};

	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'engines');
	const canDelete = hasDeletePermission(permissions, 'engines');

	const favoriteIconClickHandler = () => {
		if (!isFeatured) featureEngine(engine.id);
	};

	const { mutate: featureEngine } = useMutation({
		mutationFn: (engineId: number) =>
			enginesService.updateEngineForm(engineId, { featured: true }, undefined),
		onMutate: () => setIsFeatured(true), // optimistic update
		onSuccess: () => {
			notification.success(saveSuccessNotification());
			queryClient.invalidateQueries({ queryKey: ['engines'] });
		},
		onError: (err: unknown) => {
			notification.error(
				mcErrorNotification('Error', err, 'set', 'featured engine')
			);
			setIsFeatured(false);
		},
	});

	const { mutate: deleteEngineHandler } = useMutation({
		mutationFn: (engineId: number) => enginesService.deleteEngineById(engineId),
		onSuccess: () => {
			notification.success(deleteSuccessNotification(engine.name));
			queryClient.invalidateQueries({ queryKey: ['engines'] });
		},
		onError: (err: unknown) =>
			notification.error(mcErrorNotification('Error', err, 'delete', 'engine')),
	});

	return (
		<div>
			<ItemContainer styles={styles}>
				<div className={style.textItem} style={{ width: '30%' }}>
					<div>{engine.name}</div>
				</div>
				<div className={style.statusItem} style={{ width: '25%' }}>
					<div
						className={style.statusCircle}
						style={{
							background:
								engine.entityStatus === 'PUBLISHED'
									? 'var(--add-green)'
									: engine.entityStatus === 'PUBLISHED_INTERNALLY'
									? 'var(--add-yellow)'
									: 'var(--add-orange)',
						}}
					></div>
					{capitalize(engine.entityStatus ?? '')}
				</div>
				<div className={style.textItem} style={{ width: '35%' }}>
					{engine.version}
				</div>
				<div className={style.buttonsItem} style={{ gap: '0.50rem' }}>
					<div
						className={`${style.favoriteIcon} ${
							isFeatured ? style.favoriteIconClicked : ''
						}`}
					>
						<McIconButton
							icon={<StarIcon />}
							disabled={!canEdit}
							onClick={favoriteIconClickHandler}
						/>
					</div>
					<McIconButton
						icon={<IconEdit />}
						disabled={!canEdit}
						onClick={clickEditBtnHandler}
					/>
					<McIconButton
						icon={<IconTrash />}
						disabled={!canDelete}
						onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
							e.preventDefault();
							modal.confirm(
								deleteItemModal(
									() => deleteEngineHandler(engine.id),
									engine.name
								)
							);
						}}
					/>
					<div style={{ cursor: 'auto' }}>
						<MoreVertical />
					</div>
				</div>
			</ItemContainer>
			{selectedToUpdate === engine.id && (
				<EditEngineForm
					engineWithFullSizeImg={engine}
					setSelectedToUpdate={setSelectedToUpdate}
				/>
			)}
		</div>
	);
};
