import { useState, useEffect, FC, useMemo } from 'react';
import { SearchField } from 'components/fields';
import { AddButton } from 'components/buttons';
import style from 'assets/styles/manageElements.module.scss';
import { useDebounce } from 'hooks/useDebounce';
import { App, Spin } from 'antd';
import { AlertCircle } from 'assets/icons/svg';
import { ManageOneModule } from 'modules/ManageModules/ManageOneModule';
import { McButton } from 'components/mc';
import { useInfiniteQuery } from '@tanstack/react-query';
import modulesService from 'services/ModulesService';
import { mcErrorNotification } from 'utils/Notifications';
import { ModuleItemDto } from 'api';
import { EditModuleForm } from 'modules/ManageModules/EditModuleForm';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { hasWritePermission } from 'utils/permissions';

export const ManageModules: FC = () => {
	const { notification } = App.useApp();
	const [showAddModuleForm, setShowAddModuleForm] = useState(false);
	const [searchedValue, setSearchedValue] = useState('');
	const [selectedToUpdate, setSelectedToUpdate] = useState<number | null>(null);
	const debouncedSearchTerm = useDebounce(searchedValue, 700);
	const PAGE_SIZE = 10;

	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'modules');

	const {
		data: modulePages,
		error: getModulesError,
		hasNextPage,
		isLoading,
		isFetching,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: ['modules', 'infinite', debouncedSearchTerm],
		queryFn: ({ pageParam: pageNr }) =>
			modulesService
				.getModulesList(pageNr, PAGE_SIZE, debouncedSearchTerm)
				.then((res) => res.data),
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.pageNumber + 1;
			return nextPageNr < lastPage.allPages ? nextPageNr : undefined;
		},
	});

	const modules: ModuleItemDto[] = useMemo(
		() =>
			!modulePages
				? []
				: modulePages.pages.reduce(
						(acc, page) => [...acc, ...page.content],
						[] as ModuleItemDto[]
				  ),
		[modulePages]
	);

	const allModulesCount = !!modulePages ? modulePages.pages[0].allElements : 0;

	useEffect(() => {
		if (!getModulesError) return;
		notification.error(
			mcErrorNotification('Error', getModulesError, 'fetch', 'modules')
		);
	}, [getModulesError, notification]);

	return (
		<>
			<div className={style.container}>
				<h1 className={style.header}>Manage Modules</h1>
				{!isLoading && searchedValue === '' && allModulesCount === 0 ? (
					<>
						<div
							className={style.noElementsInfo}
							style={{ marginTop: '2rem', marginBottom: '1.5rem' }}
						>
							There are no Modules to show here
							<AlertCircle />
						</div>
						<AddButton
							onClick={() => setShowAddModuleForm((prev) => !prev)}
							disabled={!canEdit}
						/>
					</>
				) : (
					<>
						<div className={style.amountInfo}>
							{`Displaying ${modules.length} out of ${allModulesCount} modules`}
						</div>
						<div className={style.searchAddFields}>
							<div
								style={{ flex: 'auto' }}
								className={style.searchFieldWrapper}
							>
								<SearchField
									placeholder={'Search'}
									value={searchedValue}
									onChange={(e) => {
										setSearchedValue(e.target.value);
									}}
								/>
							</div>
							<AddButton
								onClick={() => setShowAddModuleForm((prev) => !prev)}
								disabled={!canEdit}
							/>
						</div>
					</>
				)}
				<div style={{ marginTop: '2rem' }}>
					{showAddModuleForm && (
						<EditModuleForm
							setShowAddModuleForm={setShowAddModuleForm}
							module={'new'}
						/>
					)}
				</div>
				{allModulesCount !== 0 && (
					<h3 className={style.listTitle}>Module List</h3>
				)}
				<div>
					<Spin spinning={isLoading} size="large">
						{!isLoading && allModulesCount === 0 && searchedValue !== '' && (
							<div className={style.noElementsInfo}>
								There are no Modules to show here
								<AlertCircle />
							</div>
						)}
						{allModulesCount === 0 ? (
							<></>
						) : (
							<div className={style.itemsContainer}>
								{modules.map((module) => {
									return (
										<div key={module.id}>
											<ManageOneModule
												module={module}
												setSelectedToUpdate={setSelectedToUpdate}
												selectedToUpdate={selectedToUpdate}
											/>
										</div>
									);
								})}
							</div>
						)}
					</Spin>
				</div>
				{allModulesCount !== 0 && hasNextPage && (
					<div
						className={style.paginationContainer}
						onClick={() => {
							fetchNextPage();
						}}
					>
						<McButton disabled={isLoading || isFetching}>View more</McButton>
					</div>
				)}
			</div>
		</>
	);
};
