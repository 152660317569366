export const readPermissions: Record<string, string> = {
	news: 'read:news',
	support: 'read:support',
	users: 'read:users',
	organizations: 'read:organizations',
	engines: 'read:engines',
	releases: 'read:app_releases',
	templates: 'read:templates',
	licenses: 'read:licenses',
	featureflags: 'read:feature-flags',
	modules: 'read:modules',
	applications: 'read:applications',
	statistics: 'read:statistics',
};

export const writePermissions: Record<string, string> = {
	news: 'write:news',
	support: 'write:support-items',
	users: 'write:users',
	organizations: 'write:organizations',
	engines: 'write:engines',
	releases: 'write:app_releases',
	templates: 'write:templates',
	licenses: 'write:licenses',
	featureflags: 'write:feature-flags',
	modules: 'write:modules',
	applications: 'write:applications',
	statistics: 'write:statistics',
};

export const deletePermissions: Record<string, string> = {
	news: 'write:news',
	support: 'write:support-items',
	users: 'delete:users',
	organizations: 'write:organizations',
	engines: 'write:engines',
	releases: 'write:app_releases',
	templates: 'write:templates',
	licenses: 'write:licenses',
	featureflags: 'write:feature-flags',
	modules: 'write:modules',
	applications: 'write:applications',
	statistics: 'write:statistics',
};

/**
 * Checks if the user has read permission for a specific resource.
 */
export const hasReadPermission = (
	userPermissions: string[],
	resource: string
): boolean => {
	return userPermissions.includes(readPermissions[resource]);
};

/**
 * Checks if the user has write permission for a specific resource.
 */
export const hasWritePermission = (
	userPermissions: string[],
	resource: string
): boolean => {
	return userPermissions.includes(writePermissions[resource]);
};

/**
 * Checks if the user has write permission for a specific resource.
 */
export const hasDeletePermission = (
	userPermissions: string[],
	resource: string
): boolean => {
	return userPermissions.includes(deletePermissions[resource]);
};
