import { useState, useEffect, FC, useMemo } from 'react';
import { ManageOneSubscription } from 'modules/ManageSubscriptions/ManageOneSubscription';
import { SearchField } from 'components/fields';
import { AddButton } from 'components/buttons';
import style from 'assets/styles/manageElements.module.scss';
import { useDebounce } from 'hooks/useDebounce';
import { App, Spin } from 'antd';
import {
	AlertCircle,
	FloatingSubscriptionIcon,
	StandaloneSubscriptionIcon,
} from 'assets/icons/svg';
import { useParams } from 'react-router-dom';
import subscriptionsService from 'services/SubscriptionsService';
import { SubscriptionStatus } from 'types/api';
import {
	AdminSubscriptionDto,
	LicensingRequestStatus,
	SubscriptionExpiryRequestType,
	SubscriptionType,
} from 'api';
import { McButton } from 'components/mc';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { EditSubscriptionForm } from 'modules/ManageSubscriptions/EditSubscriptionForm';
import { mcErrorNotification } from 'utils/Notifications';
import FilterTagField, {
	FilterCategory,
} from 'components/fields/FilterTagField';
import FilterTagContainer from 'components/FilterTagContainer';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { hasWritePermission } from 'utils/permissions';

export const ManageLicenses: FC = () => {
	const params = useParams();
	const [showAddSubscriptionForm, setShowAddSubscriptionForm] = useState(false);
	const [searchedValue, setSearchedValue] = useState('');
	const debouncedSearchTerm = useDebounce(searchedValue, 700);
	const [selectedToUpdate, setSelectedToUpdate] = useState<number | null>(null); // <- set this as the value of url param
	const PAGE_SIZE = 10;
	const { notification } = App.useApp();

	// Filter field states
	const [activeFilter, setActiveFilter] = useState(false);
	const [inactiveFilter, setInactiveFilter] = useState(false);
	const [openRequestFilter, setOpenRequestFilter] = useState(false);
	const [expiredFilter, setExpiredFilter] = useState(false);
	const [expiresIn30Filter, setExpiresIn30Filter] = useState(false);
	const [expiresInFutureFilter, setExpiresInFutureFilter] = useState(false);
	const [floatingTypeFilter, setFloatingTypeFilter] = useState(false);
	const [standaloneFilter, setStandaloneFilter] = useState(false);

	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'licenses');

	// The parameters to send with the paginated query
	const activeStatus: SubscriptionStatus | undefined = useMemo(
		() => (activeFilter ? 'ACTIVE' : inactiveFilter ? 'INACTIVE' : undefined),
		[activeFilter, inactiveFilter]
	);
	const subscriptionType: SubscriptionType | undefined = useMemo(
		() =>
			floatingTypeFilter ? 'NETWORK' : standaloneFilter ? 'LOCAL' : undefined,
		[floatingTypeFilter, standaloneFilter]
	);
	const requestStatus: LicensingRequestStatus | undefined = useMemo(
		() => (openRequestFilter ? 'PENDING' : undefined),
		[openRequestFilter]
	);
	const subscriptionExpiry: SubscriptionExpiryRequestType | undefined = useMemo(
		() =>
			expiredFilter
				? 'EXPIRED'
				: expiresIn30Filter
				? 'SOON'
				: expiresInFutureFilter
				? 'FUTURE'
				: undefined,
		[expiredFilter, expiresIn30Filter, expiresInFutureFilter]
	);

	const isFiltered: boolean = useMemo(
		() =>
			!!activeStatus ||
			!!subscriptionType ||
			!!requestStatus ||
			!!subscriptionExpiry ||
			!!(debouncedSearchTerm !== undefined && debouncedSearchTerm !== ''),
		[
			activeStatus,
			debouncedSearchTerm,
			requestStatus,
			subscriptionExpiry,
			subscriptionType,
		]
	);

	const filterCategories: FilterCategory[] = useMemo(() => {
		const statusCategory: FilterCategory = {
			categoryName: 'Status',
			filterItems: [
				{
					label: 'Active',
					enabled: activeFilter,
					toggleEnabled: () => setActiveFilter((prev) => !prev),
					isOptionDisabled: inactiveFilter,
				},
				{
					label: 'Inactive',
					enabled: inactiveFilter,
					toggleEnabled: () => setInactiveFilter((prev) => !prev),
					isOptionDisabled: activeFilter,
				},
				{
					label: 'Open Requests',
					enabled: openRequestFilter,
					toggleEnabled: () => setOpenRequestFilter((prev) => !prev),
				},
				{
					label: 'Expired',
					enabled: expiredFilter,
					toggleEnabled: () => setExpiredFilter((prev) => !prev),
					isOptionDisabled: expiresIn30Filter || expiresInFutureFilter,
				},
				{
					label: 'Expires in ≤ 30 days',
					enabled: expiresIn30Filter,
					toggleEnabled: () => setExpiresIn30Filter((prev) => !prev),
					isOptionDisabled: expiredFilter || expiresInFutureFilter,
				},
				{
					label: 'Expires in > 30 days',
					enabled: expiresInFutureFilter,
					toggleEnabled: () => setExpiresInFutureFilter((prev) => !prev),
					isOptionDisabled: expiresIn30Filter || expiredFilter,
				},
			],
		};
		const subscriptionTypeCategory: FilterCategory = {
			categoryName: 'Subscription Type',
			filterItems: [
				{
					label: 'Floating',
					enabled: floatingTypeFilter,
					toggleEnabled: () => setFloatingTypeFilter((prev) => !prev),
					isOptionDisabled: standaloneFilter,
					icon: <FloatingSubscriptionIcon />,
				},
				{
					label: 'Standalone',
					enabled: standaloneFilter,
					toggleEnabled: () => setStandaloneFilter((prev) => !prev),
					isOptionDisabled: floatingTypeFilter,
					icon: <StandaloneSubscriptionIcon />,
				},
			],
		};

		return [statusCategory, subscriptionTypeCategory];
	}, [
		activeFilter,
		expiredFilter,
		expiresIn30Filter,
		expiresInFutureFilter,
		floatingTypeFilter,
		inactiveFilter,
		openRequestFilter,
		standaloneFilter,
	]);

	const {
		data: subscriptionPages,
		error: getSubscriptionsError,
		hasNextPage,
		isLoading: isLoadingPaginated,
		isFetching,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: [
			'subscriptions',
			'admin',
			'infinite',
			debouncedSearchTerm,
			activeStatus,
			requestStatus,
			subscriptionExpiry,
			subscriptionType,
		],
		queryFn: ({ pageParam: pageNr }) =>
			subscriptionsService
				.getPaginatedAdminSubscriptions(
					pageNr,
					PAGE_SIZE,
					debouncedSearchTerm,
					activeStatus,
					requestStatus,
					subscriptionExpiry,
					subscriptionType
				)
				.then((res) => res.data),
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.pageNumber + 1;
			return nextPageNr < lastPage.allPages ? nextPageNr : undefined;
		},
	});

	const subscriptions: AdminSubscriptionDto[] = useMemo(
		() =>
			!!subscriptionPages
				? subscriptionPages.pages.reduce(
						(acc, page) => [...acc, ...page.content],
						[] as AdminSubscriptionDto[]
				  )
				: [],
		[subscriptionPages]
	);

	const subscriptionsCount =
		!!subscriptionPages && subscriptionPages.pages.length > 0
			? subscriptionPages.pages[0].allElements
			: 0;

	const {
		data: providedSubscription,
		error: fetchProvidedSubscriptionError,
		isLoading: isLoadingSingle,
	} = useQuery({
		queryKey: ['subscriptions', 'admin', params.id],
		queryFn: () =>
			subscriptionsService
				.getAdminSubscriptionById(parseInt(params.id!))
				.then((res) => {
					setSelectedToUpdate(parseInt(params.id!));
					return res.data;
				}),
		enabled: params.id !== undefined && !isNaN(parseInt(params.id)),
	});

	useEffect(() => {
		if (!fetchProvidedSubscriptionError) return;
		notification.warning(
			mcErrorNotification(
				'Error',
				fetchProvidedSubscriptionError,
				'fetch',
				'selected subscription'
			)
		);
	}, [fetchProvidedSubscriptionError, notification]);

	useEffect(() => {
		if (!getSubscriptionsError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				getSubscriptionsError,
				'fetch',
				'subscription list'
			)
		);
	}, [getSubscriptionsError, notification]);

	const isLoading = isLoadingPaginated || isLoadingSingle;

	return (
		<>
			<div className={style.container}>
				<h1 className={style.header}>Manage Licensing</h1>
				{!providedSubscription && (
					<>
						{!isLoading && !isFiltered && subscriptionsCount === 0 ? (
							<>
								<div
									className={style.noElementsInfo}
									style={{ marginTop: '2rem', marginBottom: '1.5rem' }}
								>
									There are no Subscriptions to show here
									<AlertCircle />
								</div>
								<AddButton
									onClick={() => setShowAddSubscriptionForm((prev) => !prev)}
									disabled={!canEdit}
								/>
							</>
						) : (
							<>
								<div className={style.amountInfo}>
									{`Displaying ${subscriptions.length} out of ${subscriptionsCount} subscriptions`}
								</div>
								<div className={style.searchAddFields}>
									<div
										style={{ flex: 'auto' }}
										className={style.searchFieldWrapper}
									>
										<SearchField
											placeholder={'Search'}
											value={searchedValue}
											onChange={(e) => {
												setSearchedValue(e.target.value);
											}}
										/>
									</div>
									<div>
										<FilterTagField filterCategories={filterCategories} />
									</div>
									<AddButton
										onClick={() => setShowAddSubscriptionForm((prev) => !prev)}
										disabled={!canEdit}
									/>
								</div>
							</>
						)}
						<FilterTagContainer filterCategories={filterCategories} />

						<div style={{ marginTop: '2rem' }}>
							{showAddSubscriptionForm && (
								<EditSubscriptionForm
									hideForm={() => setShowAddSubscriptionForm(false)}
									subscription={'new'}
								/>
							)}
						</div>
						{subscriptionsCount !== 0 && (
							<h3 className={style.listTitle}>Subscription List</h3>
						)}
					</>
				)}
				<div>
					<Spin spinning={isLoading} size="large">
						{!isLoading && subscriptionsCount === 0 && !!isFiltered && (
							//|| selStatus !== undefined
							<div className={style.noElementsInfo}>
								There are no Subscriptions to show here
								<AlertCircle />
							</div>
						)}
						{subscriptionsCount !== 0 && (
							<div className={style.itemsContainer}>
								{!!providedSubscription ? (
									<ManageOneSubscription
										subscription={providedSubscription}
										setSelectedToUpdate={setSelectedToUpdate}
										selectedToUpdate={selectedToUpdate}
									/>
								) : (
									subscriptions.map((subscription) => {
										return (
											<div key={subscription.id}>
												<ManageOneSubscription
													subscription={subscription}
													setSelectedToUpdate={setSelectedToUpdate}
													selectedToUpdate={selectedToUpdate}
												/>
											</div>
										);
									})
								)}
							</div>
						)}
					</Spin>
				</div>
				{subscriptionsCount !== 0 && hasNextPage && !providedSubscription && (
					<div
						className={style.paginationContainer}
						onClick={() => fetchNextPage()}
					>
						<McButton disabled={isLoading || isFetching}>View more</McButton>
					</div>
				)}
			</div>
		</>
	);
};
