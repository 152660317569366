import { FC, useEffect, useMemo, useState } from 'react';
import { ManageOneAppRelease } from 'modules/ManageAppReleases/ManageOneAppRelease';
import { SearchField } from 'components/fields';
import { AddButton } from 'components/buttons';
import { AppReleaseForm } from 'modules/ManageAppReleases/EditAppReleaseForm';
import { AlertCircle } from 'assets/icons/svg';
import { App, Spin } from 'antd';
import style from 'assets/styles/manageElements.module.scss';
import { useDebounce } from 'hooks/useDebounce';
import { FilterField } from 'components/fields';
import {
	StatusSelection,
	statusSelectionLabels,
	statusSelectionValues,
} from 'types/status-selection';
import { SelectApplication } from 'components/selects/SelectApplication';
import { McButton } from 'components/mc';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import appReleasesService from 'services/AppReleasesService';
import { ApplicationDto, ApplicationReleaseDto } from 'api';
import { mcErrorNotification } from 'utils/Notifications';
import applicationsService from 'services/ApplicationsService';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { hasWritePermission } from 'utils/permissions';

export const ManageAppReleases: FC = () => {
	const [showAddForm, setShowAddForm] = useState(false);
	const [searchQueryText, setSearchQueryText] = useState('');
	const [filterAppId, setFilterAppId] = useState<number>();
	const [selStatus, setSelStatus] = useState<StatusSelection>();
	const [selectedToUpdate, setSelectedToUpdate] = useState<number | null>(null);
	const debouncedSearch = useDebounce(searchQueryText, 700);
	const PAGE_SIZE = 10;
	const { notification } = App.useApp();

	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'releases');

	const {
		data: applicationReleasePages,
		error: getAppicationReleasesError,
		hasNextPage,
		isLoading: isLoadingApplicationReleases,
		isFetching,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: [
			'applications',
			'releases',
			'infinite',
			debouncedSearch,
			selStatus,
			filterAppId,
		],
		queryFn: ({ pageParam: pageNr }) =>
			appReleasesService
				.getApplicationReleasesList(
					pageNr,
					PAGE_SIZE,
					debouncedSearch,
					undefined,
					undefined,
					selStatus,
					filterAppId
				)
				.then((res) => res.data),
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.pageNumber + 1;
			return nextPageNr < lastPage.allPages ? nextPageNr : undefined;
		},
	});

	const applicationReleases: ApplicationReleaseDto[] = useMemo(
		() =>
			!!applicationReleasePages
				? applicationReleasePages.pages.reduce(
						(acc, page) => [...acc, ...page.content],
						[] as ApplicationReleaseDto[]
				  )
				: [],
		[applicationReleasePages]
	);

	const applicationReleaseCount =
		!!applicationReleasePages && applicationReleasePages.pages.length > 0
			? applicationReleasePages.pages[0].allElements
			: 0;

	const { data: applications, isLoading: isLoadingApplications } = useQuery({
		queryKey: ['applications'],
		queryFn: () =>
			applicationsService
				.getApplicationsList(0, 50, undefined)
				.then((res) => res.data.content),
		initialData: [],
	});

	const applicationLookup = useMemo(() => {
		const lookup: { [applicationId: number]: ApplicationDto } = {};
		applications.forEach(
			(application) => (lookup[application.id] = application)
		);
		return lookup;
	}, [applications]);

	const isLoading = isLoadingApplicationReleases || isLoadingApplications;

	useEffect(() => {
		if (!getAppicationReleasesError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				getAppicationReleasesError,
				'fetch',
				'application release list'
			)
		);
	}, [getAppicationReleasesError, notification]);

	return (
		<>
			<div className={style.container}>
				<h1 className={style.header}>Manage Application Releases</h1>
				{!isLoading &&
				searchQueryText === '' &&
				selStatus === undefined &&
				filterAppId === undefined &&
				applicationReleaseCount === 0 ? (
					<>
						<div
							className={style.noElementsInfo}
							style={{ marginTop: '2rem', marginBottom: '1.5rem' }}
						>
							There are no Application Releases to show here
							<AlertCircle />
						</div>
						<AddButton
							onClick={() => setShowAddForm((prev) => !prev)}
							disabled={!canEdit}
						/>
					</>
				) : (
					<>
						<div className={style.amountInfo}>
							{`Displaying ${applicationReleases.length} out of ${applicationReleaseCount} application releases`}
						</div>
						<div className={style.searchAddFields}>
							<div
								style={{ flex: 'auto' }}
								className={style.searchFieldWrapper}
							>
								<SearchField
									placeholder={'Search for application version'}
									value={searchQueryText}
									onChange={(e) => {
										setSearchQueryText(e.target.value);
									}}
								/>
							</div>
							<div style={{ flex: 'auto' }}>
								<SelectApplication
									value={filterAppId}
									filterField
									setSelectedField={setFilterAppId}
									placeholder="All applications"
									allowClear
								/>
							</div>
							<div style={{ flex: '0 0 10.3125rem' }}>
								<FilterField
									label={'Status: '}
									selValue={selStatus}
									values={statusSelectionValues}
									labels={statusSelectionLabels}
									admin={true}
									setSelectedField={setSelStatus}
								/>
							</div>
							<AddButton
								onClick={() => setShowAddForm((prev) => !prev)}
								disabled={!canEdit}
							/>
						</div>
					</>
				)}

				<div style={{ marginTop: '2rem' }}>
					{showAddForm && (
						<AppReleaseForm
							applicationRelease="new"
							hideForm={() => setShowAddForm(false)}
						/>
					)}
				</div>
				{applicationReleaseCount !== 0 && (
					<h3 className={style.listTitle}>Application release list</h3>
				)}
				<div>
					<Spin spinning={isLoading} size="large">
						{!isLoading &&
							applicationReleaseCount === 0 &&
							(searchQueryText !== '' ||
								selStatus !== undefined ||
								filterAppId !== undefined) && (
								<div className={style.noElementsInfo}>
									There are no Application Releases to show here
									<AlertCircle />
								</div>
							)}
						{
							<div className={style.itemsContainer}>
								{applicationReleases.map((applicationRelease) => {
									return (
										applicationRelease.applicationId !== undefined &&
										!!applicationLookup[applicationRelease.applicationId] && (
											<div key={applicationRelease.id}>
												<ManageOneAppRelease
													application={
														applicationLookup[applicationRelease.applicationId!]
													}
													applicationRelease={applicationRelease}
													setSelectedToUpdate={setSelectedToUpdate}
													selectedToUpdate={selectedToUpdate}
												/>
											</div>
										)
									);
								})}
							</div>
						}
					</Spin>
				</div>
				{applicationReleaseCount !== 0 && hasNextPage && (
					<div
						className={style.paginationContainer}
						onClick={() => fetchNextPage()}
					>
						<McButton disabled={isLoading || isFetching}>View more</McButton>
					</div>
				)}
			</div>
		</>
	);
};
