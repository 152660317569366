import { yupResolver } from '@hookform/resolvers/yup';
import { CurrentUserUpdateDto } from 'api';
import { IconClose } from 'assets/icons/svg';
import 'assets/styles/ProfileSettingsPopOver.scss';
import { PictureField } from 'components/fields';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { MouseEvent, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { authState, checkAuth, updateCurrentUser } from 'store/slices/auth';
import { deleteUserAvatar } from 'store/slices/oneUser';
import { isUserDto } from 'types/user';
import { capitalize } from 'utils';
import { editProfileSchema } from 'validations/FormValidation';
import { McButton, McTextBox } from './mc';
import { App } from 'antd';
import {
	errorNotification,
	saveSuccessNotification,
} from 'utils/Notifications';
import { generateDisplayErrorMessage, getRequestError } from 'utils/errors';

interface FormValues {
	firstName: string;
	lastName: string;
}

export const ProfileSettingsPopOver = ({
	setVisible,
	visible,
}: {
	setVisible: (value: boolean) => void;
	visible: boolean;
}) => {
	const dispatch = useAppDispatch();
	const { user } = useAppSelector(authState);
	const { notification } = App.useApp();

	const {
		handleSubmit,
		setValue,
		register,
		formState: { errors },
	} = useForm<FormValues>({
		mode: 'onBlur',
		resolver: yupResolver(editProfileSchema),
	});

	const [selectedImage, setSelectedImage] = useState<Blob | undefined>();
	const [isAvatarDeleted, setIsAvatarDeleted] = useState(false);

	useEffect(() => {
		if (!isUserDto(user)) return;

		setValue('firstName', user.firstName ?? '', {
			shouldValidate: false,
			shouldDirty: false,
		});
		setValue('lastName', user.lastName ?? '', {
			shouldValidate: false,
			shouldDirty: false,
		});
	}, [user, setValue]);

	const handleClose = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		setVisible(false);
	};

	const editProfile: SubmitHandler<FormValues> = (data) => {
		if (!isUserDto(user)) return;

		const firstChanged = data.firstName.trim() !== user.firstName;
		const lastChanged = data.lastName.trim() !== user.lastName;
		const imageChanged = selectedImage instanceof File;

		if (firstChanged || lastChanged || imageChanged || isAvatarDeleted) {
			const userDto: CurrentUserUpdateDto = {
				firstName: firstChanged ? data.firstName.trim() : undefined,
				lastName: lastChanged ? data.lastName.trim() : undefined,
			};

			if (isAvatarDeleted) {
				dispatch(deleteUserAvatar(user.email)).finally(() =>
					setIsAvatarDeleted(false)
				);
			}

			dispatch(
				updateCurrentUser({
					dto: userDto,
					image: imageChanged ? selectedImage : undefined,
				})
			)
				.then(() => {
					dispatch(checkAuth());
				})
				.then(() => {
					notification.success(saveSuccessNotification());
					setVisible(false);
				})
				.catch((error) => {
					const errorDto = getRequestError(error);
					notification.error(
						errorNotification(generateDisplayErrorMessage(errorDto))
					);
				});
		} else {
			setVisible(false);
		}
	};

	if (!isUserDto(user)) return <></>;

	return (
		<form
			className="popoverSettings-content"
			onSubmit={handleSubmit(editProfile)}
		>
			<div className="popoverSettings-avatar">
				<PictureField
					selectedImage={selectedImage}
					setSelectedImage={setSelectedImage}
					existingImagePath={user.avatarPath}
					setIsImageDeleted={setIsAvatarDeleted}
					isImageDeleted={isAvatarDeleted}
					isAvatar={true}
				/>
				<div style={{ cursor: 'pointer' }} onClick={handleClose}>
					<IconClose />
				</div>
			</div>

			<div className="popoverSettings-form">
				<McTextBox
					{...register('firstName')}
					placeholder={'John'}
					label={'First Name'}
					error={errors.firstName?.message}
				/>
				<McTextBox
					{...register('lastName')}
					placeholder={'Smith'}
					label={'Last Name'}
					error={errors.lastName?.message}
				/>
				<McTextBox value={user.company ?? ''} label={'Company'} readOnly />
				<McTextBox
					value={user.department ?? ''}
					label={'Department'}
					readOnly
				/>
				<McButton type="submit" primary>
					Save
				</McButton>
			</div>
		</form>
	);
};
