import { useEffect, useMemo, useState } from 'react';
import { CheckMarkIcon, IconTrash } from 'assets/icons/svg';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { FilterField } from 'components/fields';
import style from 'assets/styles/notifications.module.scss';
import { Link } from 'react-router-dom';
import { NotificationCategory } from 'types/api';
import { NotificationDto } from 'api';
import { App, Empty, Spin, Tooltip } from 'antd';
import {
	useInfiniteQuery,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import notificationsService from 'services/NotificationsService';
import {
	deleteItemModal,
	deleteSuccessNotification,
	mcErrorNotification,
} from 'utils/Notifications';

type CategorySelection = NotificationCategory | undefined;
const categorySelectionValues: CategorySelection[] = [
	undefined,
	'ADMIN_NOTICE',
	'NEWS',
	'SOFTWARE_RELEASE',
	'USER_NOTICE',
];
const categoryLabels: Record<NotificationCategory, string> = {
	ADMIN_NOTICE: 'Admin Notices',
	NEWS: 'News',
	SOFTWARE_RELEASE: 'Software Releases',
	USER_NOTICE: 'User Notices',
};
const categorySelectionLabels = categorySelectionValues.map((c) =>
	c ? categoryLabels[c] : 'Show all'
);

export const Notifications = () => {
	const { user, isGuest } = useAppSelector(authState);
	const queryClient = useQueryClient();
	const [selType, setSelType] = useState<NotificationCategory | undefined>(
		undefined
	);
	const [onlyUnread, setOnlyUnread] = useState<boolean>(false);
	const { notification, modal } = App.useApp();
	const PAGE_SIZE = 30;

	const [notificationIdRead, setNotificationIdRead] = useState<number[]>([]);

	const { mutate: deleteAllNotifications } = useMutation({
		mutationFn: () => notificationsService.deleteAllUserNotifications(selType),
		onSuccess: () => {
			notification.success(deleteSuccessNotification('all notifications'));
			queryClient.invalidateQueries({
				queryKey: ['notifications'],
			});
		},
		onError: (err: unknown) =>
			notification.error(
				mcErrorNotification('Error', err, 'delete', 'all notifications')
			),
	});

	const { mutate: deleteNotification } = useMutation({
		mutationFn: (notificationId: number) =>
			notificationsService.deleteNotificationById(notificationId),
		onSuccess: () => {
			notification.success(deleteSuccessNotification('notification'));
			queryClient.invalidateQueries({
				queryKey: ['notifications'],
			});
		},
		onError: (err: unknown) =>
			notification.error(
				mcErrorNotification('Error', err, 'delete', 'notification')
			),
	});

	const { mutate: markAsRead } = useMutation({
		mutationFn: (notificationId: number) =>
			notificationsService.updateNotificationStatus({
				notificationsItemId: notificationId,
				read: true,
			}),
		onMutate: (notificationId: number) => {
			if (!notificationIdRead.includes(notificationId))
				setNotificationIdRead((notificationIdRead) => [
					...notificationIdRead,
					notificationId,
				]);
		},
		onSuccess: () =>
			queryClient.invalidateQueries({
				queryKey: ['notifications', 'unread', 'count'],
			}),
		onError: (err: unknown, notificationId: number) => {
			notification.error(mcErrorNotification('Error', err, 'mark', 'as read'));
			notificationIdRead.filter((id) => id !== notificationId);
		},
	});

	const { mutate: markAllAsRead } = useMutation({
		mutationFn: () => notificationsService.markAllRead(selType),
		onSuccess: () =>
			queryClient.invalidateQueries({
				queryKey: ['notifications'],
			}),
		onError: (err: unknown) =>
			notification.error(
				mcErrorNotification('Error', err, 'mark all', 'as read')
			),
	});

	const {
		data: notificationPages,
		isLoading: isLoadingNotifications,
		error: fetchingNotificationsError,
		hasNextPage,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: ['notifications', 'infinite', selType, onlyUnread],
		queryFn: ({ pageParam: pageNr }) =>
			notificationsService
				.listNotifications(pageNr, PAGE_SIZE, selType, onlyUnread)
				.then((res) => res.data),
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.pageNumber + 1;
			return nextPageNr < lastPage.allPages ? nextPageNr : undefined;
		},
		enabled: !isGuest,
	});

	const notifications = useMemo(
		() =>
			!notificationPages
				? []
				: notificationPages.pages.reduce(
						(acc, page) => [...acc, ...page.content],
						[] as NotificationDto[]
				  ),
		[notificationPages]
	);

	const notificationsCount =
		!!notificationPages && notificationPages.pages.length > 0
			? notificationPages.pages[0].allElements
			: 0;

	useEffect(() => {
		if (!fetchingNotificationsError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				fetchingNotificationsError,
				'fetch',
				'notifications'
			)
		);
	}, [fetchingNotificationsError, notification]);

	const onScroll = (e: any) => {
		e.persist();
		const target = e.target;
		if (
			target.scrollTop + target.offsetHeight >= target.scrollHeight &&
			hasNextPage
		) {
			fetchNextPage();
		}
	};

	return (
		<div className={style.wrapper}>
			<div className={style.title}>
				<h4>Notifications</h4>
				<FilterField
					selValue={selType}
					values={categorySelectionValues}
					labels={categorySelectionLabels}
					admin={false}
					notifications={true}
					font={'0.875rem'}
					setSelectedField={(v) => setSelType(v)}
				/>
			</div>
			<div className="flex align-center between">
				<div className={style.menuBar}>
					<div
						className={`${style.menuItem} ${onlyUnread ? '' : style.selected}`}
						onClick={() => setOnlyUnread(false)}
					>
						All
					</div>
					<div
						className={`${style.menuItem} ${onlyUnread ? style.selected : ''}`}
						onClick={() => setOnlyUnread(true)}
					>
						Unread
					</div>
				</div>

				<small>{`Displaying ${notifications.length} out of ${notificationsCount} notifications`}</small>
			</div>
			<div className={style.divider} />

			<div className={style.notificationsList} onScroll={onScroll}>
				<Spin spinning={isLoadingNotifications}>
					{notifications.length > 0 ? (
						notifications.map((notice) => {
							return (
								<div
									key={notice.id}
									className={`${style.notificationItem} ${
										notice.read || notificationIdRead.includes(notice.id)
											? ''
											: style.unread
									}`}
								>
									<div className={style.notificationTitle}>
										<Tooltip title={notice.title}>
											<Link
												to={
													notice.newsItemId != null && notice.newsItemId >= 0
														? `/news/${notice.newsItemId}`
														: notice.link != null
														? notice.link
														: '.'
												}
												onClick={() => markAsRead(notice.id!)}
												style={{ textDecoration: 'none' }}
											>
												<span>{notice.title}</span>
											</Link>
										</Tooltip>
										<>
											<small>
												{'• '}
												{categoryLabels[notice.category] ?? 'News'}
											</small>
											<div
												className={style.deleteIcon}
												onClick={(e) => {
													modal.confirm(
														deleteItemModal(
															() => deleteNotification(notice.id!),
															'the notification'
														)
													);
												}}
											>
												<IconTrash size={'16px'} />
											</div>
										</>
									</div>
									<div className={style.notificationDate}></div>
								</div>
							);
						})
					) : (
						<Empty
							description={
								onlyUnread ? 'No unread notifications' : 'No notifications'
							}
						/>
					)}
				</Spin>
			</div>
			<div className={style.footer}>
				<div className={style.divider} />
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						marginTop: '1rem',
					}}
				>
					<div>
						<div className={style.checkAsRead} onClick={() => markAllAsRead()}>
							Mark all as read
							<CheckMarkIcon />
						</div>
					</div>

					<small>
						<div
							className={style.deleteAllItems}
							onClick={() => {
								modal.confirm(
									deleteItemModal(
										() => deleteAllNotifications(),
										`all ${
											!!selType ? categoryLabels[selType] : ''
										} notifications`
									)
								);
							}}
						>
							Remove all {!!selType ? categoryLabels[selType] : ''}
							<IconTrash size={'16px'} />
						</div>
					</small>
				</div>
			</div>
		</div>
	);
};
