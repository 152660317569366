import { createSlice, createAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { isUserDto } from 'types/user';
import { ErrorDTO, NotificationDto } from 'api/model';
import { getErrorMessageAnd } from 'utils/errors';
import { createAppAsyncThunk } from 'utils/rtk';
import notificationsService from 'services/NotificationsService';

export interface NotificationsState {
	notifications: NotificationDto[];
	totalCount: number;
	unreadCount: number;
	loading: boolean;
	error: ErrorDTO | null;
}

const initialState: NotificationsState = {
	notifications: [],
	unreadCount: 0,
	totalCount: 0,
	loading: false,
	error: null,
};

export const getUnreadCount = createAppAsyncThunk(
	'notifications/getUnreadCount',
	(userId: number, { rejectWithValue }) =>
		notificationsService
			.getUnreadNotificationsCounter()
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const setNotificationRead = createAppAsyncThunk(
	'notifications/setNotificationRead',
	(notificationsItemId: number, { rejectWithValue, dispatch, getState }) =>
		notificationsService
			.updateNotificationStatus({ notificationsItemId, read: true })
			.catch(getErrorMessageAnd(rejectWithValue))
			.then((r) => {
				const { user, isGuest } = (getState() as RootState).auth;
				if (!isGuest && isUserDto(user)) {
					dispatch(getUnreadCount(user.id));
				}
				return r;
			})
);

export const resetState = createAction('RESET');

const notificationsSlice = createSlice({
	name: 'tags',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getUnreadCount.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.unreadCount = payload.data;
			})
			.addCase(setNotificationRead.pending, (state, { meta }) => {
				const notice = state.notifications.find((n) => n.id === meta.arg);
				if (notice) {
					notice.read = true;
				}
			});
	},
});

export const notificationsState = (state: RootState) => state.notifications;

export const notificationsReducer = notificationsSlice.reducer;
