import React from 'react';
import style from 'assets/styles/manageElements.module.scss';
import { capitalize } from 'utils';

interface StatusBadgeProps {
	status: string;
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
	const getStatusColor = (status: string): string => {
		switch (status) {
			case 'VERIFIED':
				return 'var(--primary)';
			case 'ACTIVE':
				return 'var(--add-green)';
			case 'INACTIVE':
				return 'var(--add-red)';
			case 'PENDING':
				return 'var(--add-orange)';
			default:
				return 'var(--add-orange)'; // Fallback color
		}
	};

	return (
		<div className={style.statusItem}>
			<div
				className={style.statusCircle}
				style={{ background: getStatusColor(status) }}
			></div>
			{capitalize(status)}
		</div>
	);
};
