import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { InputField, PictureField } from 'components/fields';
import style from 'assets/styles/editAddElementForm.module.scss';
import { SelectModules } from 'components/selects';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { editApplicationSchema } from 'validations/FormValidation';
import { ApplicationDto, ApplicationSaveDto } from 'api';
import { Update } from 'types';
import { App } from 'antd';
import {
	mcErrorNotification,
	saveSuccessNotification,
} from 'utils/Notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import applicationsService from 'services/ApplicationsService';
import { getRequestError } from 'utils/errors';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { hasWritePermission } from 'utils/permissions';
import { McButton } from 'components/mc';

interface EditTypeFormProps {
	application: ApplicationDto | 'new';
	setSelectedToUpdate?: Dispatch<SetStateAction<number | null>>;
	setShowAddApplicationForm?: Dispatch<SetStateAction<boolean>>;
}

interface FormValues {
	editName: string;
	editCode: string;
	editModules: number[];
}

export const EditAppForm: React.FC<EditTypeFormProps> = ({
	application,
	setSelectedToUpdate,
	setShowAddApplicationForm,
}) => {
	const isNew = application === 'new';
	const { notification } = App.useApp();
	const queryClient = useQueryClient();

	const {
		formState: { errors },
		handleSubmit,
		control,
		setValue,
		setError: setFormError,
	} = useForm<FormValues>({
		mode: 'onBlur',
		resolver: yupResolver(editApplicationSchema),
		defaultValues: { editModules: [], editName: '' },
	});

	useEffect(() => {
		if (application && !isNew) {
			setValue('editName', application.name, {
				shouldValidate: true,
				shouldDirty: true,
			});
			setValue('editCode', application.code ?? '', {
				shouldValidate: true,
				shouldDirty: true,
			});
			setValue('editModules', application.modulesIds ?? [], {
				shouldValidate: true,
				shouldDirty: true,
			});
		}
	}, [application, isNew, setValue]);

	const [currentAppImage] = useState<Blob | undefined>();
	const [isAvatarDeleted, setIsAvatarDeleted] = useState(false);
	const [appImage, setAppImage] = useState<Blob | undefined>();

	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'applications');

	const containsSameItems = <T,>(
		arr1: T[] | undefined,
		arr2: T[] | undefined
	) => {
		const [a, b] = [arr1 ?? [], arr2 ?? []];
		return a.length === b.length && a.every((e) => b.includes(e));
	};

	const { mutate: addApplication } = useMutation({
		mutationFn: (data: FormValues) => {
			const applicationDto: ApplicationSaveDto = {
				name: data.editName,
				code: data.editCode,
				modulesIds: data.editModules,
			};

			return applicationsService.createApplicationForm(
				applicationDto,
				appImage
			);
		},
		onSuccess: (_, data) => {
			setShowAddApplicationForm?.(false);
			notification.success(saveSuccessNotification(data.editName));
			queryClient.invalidateQueries({ queryKey: ['applications'] });
		},
		onError: (error: unknown) => {
			const errorDto = getRequestError(error);
			if (
				errorDto.code === 'ENTITY_UNIQUE_CONFLICT' &&
				errorDto.target === 'name'
			)
				setFormError('editName', {
					type: 'custom',
					message: 'Another application with the same name already exist!',
				});
			notification.error(
				mcErrorNotification('Error', error, 'create', 'application')
			);
		},
	});

	const { mutate: updateApplication } = useMutation({
		mutationFn: ({
			applicationDto,
			basicPic,
		}: {
			applicationDto: ApplicationDto;
			basicPic: Blob | undefined;
		}) => applicationsService.updateApplicationForm(applicationDto, appImage),
		onSuccess: (_, data) => {
			setSelectedToUpdate?.(null);
			notification.success(saveSuccessNotification(data.applicationDto.name));
			queryClient.invalidateQueries({ queryKey: ['applications'] });
		},
		onError: (error: unknown) => {
			const errorDto = getRequestError(error);
			if (
				errorDto.code === 'ENTITY_UNIQUE_CONFLICT' &&
				errorDto.target === 'name'
			)
				setFormError('editName', {
					type: 'custom',
					message: 'Another application with the same name already exist!',
				});
			notification.error(
				mcErrorNotification('Error', error, 'update', 'application')
			);
		},
	});

	const updateApplicationHandler: SubmitHandler<FormValues> = (data) => {
		if (
			isNew ||
			(containsSameItems(application.modulesIds, data.editModules) &&
				data.editName === application.name &&
				data.editCode === application.code &&
				!(appImage instanceof File) &&
				!isAvatarDeleted)
		) {
			setSelectedToUpdate?.(null);
			return;
		}

		const applicationDto: ApplicationDto = {
			name:
				data.editName.trim() === application.name ? null : data.editName.trim(),
			code:
				data.editCode.trim() === application.code ? null : data.editCode.trim(),
			modulesIds: containsSameItems(data.editModules, application.modulesIds)
				? null
				: data.editModules,
			id: application.id,
		} satisfies Update<ApplicationDto> as ApplicationDto;

		const basicPic = appImage === currentAppImage ? undefined : appImage;

		updateApplication({ applicationDto, basicPic });
	};

	const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		setSelectedToUpdate?.(null);
		setShowAddApplicationForm?.(false);
	};

	return (
		<form
			className={isNew ? style.addFormWrapper : style.editFormWrapper}
			onSubmit={handleSubmit((data) => {
				isNew ? addApplication(data) : updateApplicationHandler(data);
			})}
		>
			<div style={{ marginBottom: '1.5rem' }}>
				<PictureField
					selectedImage={appImage}
					setSelectedImage={setAppImage}
					isAppForm={true}
					existingImagePath={isNew ? undefined : application.iconImagePath}
					isImageDeleted={isAvatarDeleted}
					setIsImageDeleted={setIsAvatarDeleted}
					isAvatar={true}
				/>
			</div>
			<div className={style.editForm}>
				<aside className={style.column}>
					<Controller
						name="editName"
						control={control}
						render={({ field }) => (
							<InputField
								placeholder={'Application name'}
								tabIndex={1}
								{...field}
								label={'Application name'}
								error={!!errors.editName}
								errorMessage={errors.editName?.message}
							/>
						)}
					/>
				</aside>
				<section className={style.column}>
					<Controller
						name="editCode"
						control={control}
						render={({ field }) => (
							<InputField
								// The actual field is named "code" in the backend and not "App id" as the label and placeholder would suggest.
								// This is in order to avoid confusing it with the entity id for the application.
								placeholder={'App id'}
								tabIndex={2}
								{...field}
								label={'App id'}
								error={!!errors.editCode}
								errorMessage={errors.editCode?.message}
							/>
						)}
					/>
				</section>
			</div>
			<Controller
				name="editModules"
				control={control}
				render={({ field }) => (
					<SelectModules
						label={'Required modules'}
						tabIndex={3}
						{...field}
						error={errors.editModules === undefined ? false : true}
						errorMessage={errors.editModules?.message}
					/>
				)}
			/>
			<div className={style.buttonsWrapper}>
				<McButton onClick={cancel}>Cancel</McButton>
				<McButton primary type="submit" disabled={!canEdit}>
					Save
				</McButton>
			</div>
		</form>
	);
};
