import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import style from 'assets/styles/editAddElementForm.module.scss';
import { DisabledScreen } from 'components/DisabledScreen';
import {
	InputDate,
	InputField,
	InputTextarea,
	PictureField,
	UploadFileField,
} from 'components/fields';
import { SelectField } from 'components/selects';
import { Controller, useForm } from 'react-hook-form';
import { engineSchema } from 'validations/FormValidation';
import { EditEngineDto, EngineAddedDto, EngineDto, EntityStatus } from 'api';
import { EntityStatuses } from 'types/api';
import { StarIcon } from 'assets/icons/svg';
import { TooltipBubble } from 'components/TooltipBubble';
import { App, Modal, Progress } from 'antd';
import {
	errorNotification,
	saveSuccessNotification,
} from 'utils/Notifications';
import {
	generateDisplayErrorMessage,
	FILE_ERROR,
	getRequestError,
} from 'utils/errors';
import ApplicationReleaseField, {
	AppIdsToReleaseIds,
} from 'components/fields/ApplicationReleaseField';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import enginesService from 'services/EnginesService';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { hasWritePermission } from 'utils/permissions';
import { McButton } from 'components/mc';

interface EditEngineFormProps {
	engineWithFullSizeImg: EngineDto | 'new';
	setSelectedToUpdate?: Dispatch<SetStateAction<number | null>>;
	setShowAddEngineForm?: Dispatcher;
}

interface FormValues {
	name: string;
	releaseDate: string;
	version: string;
	description: string;
	releaseNotes: string;
}

export const EditEngineForm: React.FC<EditEngineFormProps> = ({
	engineWithFullSizeImg,
	setSelectedToUpdate,
	setShowAddEngineForm,
}) => {
	const isNew = engineWithFullSizeImg === 'new';
	const [isFeatured, setIsFeatured] = useState(false);
	const { notification } = App.useApp();
	const {
		formState: { errors },
		register,
		handleSubmit,
		control,
		setValue,
		setError: setFormError,
	} = useForm<FormValues>({
		mode: 'onBlur',
		resolver: yupResolver(engineSchema),
	});

	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'engines');

	const [date, setDate] = useState(
		isNew ? '' : engineWithFullSizeImg.releaseDate
	);
	const [status, setStatus] = useState<EntityStatus>(
		isNew
			? 'DRAFT'
			: (engineWithFullSizeImg.entityStatus as string as EntityStatus)
	);
	const [, setCompressedFile] = useState<Blob | undefined>();
	const [fileUploadName, setFileUploadName] = useState<string | undefined>(
		isNew ? undefined : engineWithFullSizeImg.downloadLink
	);
	const [fileUpload, setFileUpload] = useState<Blob>();
	const [deleteImage, setDeleteImage] = useState(false);
	const [loadingAppSection, setLoadingAppSection] = useState(true);

	const [uploadProgress, setUploadProgress] = useState(0);
	const [showUploadModal, setShowUploadModal] = useState(false);

	const queryClient = useQueryClient();
	const [selectedApplicationReleases, setSelectedApplicationReleases] =
		useState<AppIdsToReleaseIds[]>([]);

	const [selectedImage, setSelectedImage] = useState<Blob | undefined>();

	useEffect(() => {
		if (!isNew && engineWithFullSizeImg) {
			setValue('name', engineWithFullSizeImg.name, {
				shouldValidate: true,
				shouldDirty: true,
			});
			setValue('releaseDate', engineWithFullSizeImg.releaseDate ?? '', {
				shouldValidate: true,
				shouldDirty: true,
			});
			setValue('version', engineWithFullSizeImg.version ?? '', {
				shouldValidate: true,
				shouldDirty: true,
			});
			setValue('releaseNotes', engineWithFullSizeImg.releaseNotes ?? '', {
				shouldValidate: true,
				shouldDirty: true,
			});
			setValue('description', engineWithFullSizeImg.description ?? '', {
				shouldValidate: true,
				shouldDirty: true,
			});

			setIsFeatured(engineWithFullSizeImg.featured ?? false);
		}
	}, [engineWithFullSizeImg, isNew, setValue]);

	useEffect(() => {
		setValue('releaseDate', date ?? '', {
			shouldValidate: Boolean(date),
			shouldDirty: true,
		});
	}, [date, setValue]);

	function areEqual(arr1: number[] | undefined, arr2: number[] | undefined) {
		const [a, b] = [arr1 ?? [], arr2 ?? []];
		return a.length === b.length && a.every((e) => b.includes(e));
	}

	useEffect(() => {
		if (!isNew) setFileUploadName(engineWithFullSizeImg.downloadLink);
	}, [engineWithFullSizeImg, isNew]);

	const { mutate: updateEngineHandler } = useMutation({
		mutationFn: (data: FormValues) => {
			if (isNew) return Promise.reject();
			const engineHadFile = !!engineWithFullSizeImg.downloadLink;
			const engineReqHasFile = !!fileUploadName || !!fileUpload;

			const existingReleaseIds: Array<number> = [];
			const applicationIdsForCreation: Array<number> = [];

			selectedApplicationReleases.forEach((appToRelease) => {
				appToRelease[1] === undefined
					? applicationIdsForCreation.push(appToRelease[0])
					: existingReleaseIds.push(appToRelease[1]);
			});

			const appIdsEqual =
				applicationIdsForCreation.length === 0 &&
				areEqual(
					existingReleaseIds,
					engineWithFullSizeImg.applicationReleaseIds
				);

			const nameEqual = data.name === engineWithFullSizeImg.name;
			const featEqual = isFeatured === engineWithFullSizeImg.featured;
			const descEqual = data.description === engineWithFullSizeImg.description;
			const relNotesEqual =
				data.releaseNotes === engineWithFullSizeImg.releaseNotes;
			const relDateEqual =
				data.releaseDate === engineWithFullSizeImg.releaseDate;
			const statusEqual =
				status.toUpperCase() === engineWithFullSizeImg.entityStatus;
			const versionEqual =
				data.version === engineWithFullSizeImg.version ||
				(data.version === undefined && engineWithFullSizeImg.version === '');
			const iconEqual = !(selectedImage instanceof File);
			const fileEqual = engineHadFile === engineReqHasFile && !fileUpload;

			if (
				appIdsEqual &&
				nameEqual &&
				descEqual &&
				relNotesEqual &&
				relDateEqual &&
				statusEqual &&
				versionEqual &&
				iconEqual &&
				featEqual &&
				fileEqual &&
				!deleteImage
			) {
				if (setSelectedToUpdate) setSelectedToUpdate(null);
				return Promise.reject();
			}

			const engineDto: EditEngineDto = {
				name: nameEqual ? undefined : data.name,
				releaseDate: relDateEqual ? undefined : data.releaseDate,
				existingReleaseIds: existingReleaseIds,
				applicationIdsForCreation: applicationIdsForCreation,
				version: versionEqual ? undefined : data.version,
				entityStatus: statusEqual ? undefined : status,
				basicPicture: '',
				releaseNotes: relNotesEqual ? undefined : data.releaseNotes,
				description: descEqual ? undefined : data.description,
				featured: featEqual ? undefined : isFeatured,
			};

			if (!fileEqual) {
				setUploadProgress(0);
				setShowUploadModal(true);
			}

			return enginesService.updateEngineForm(
				engineWithFullSizeImg.id,
				engineDto,
				selectedImage,
				fileUpload,
				{
					onUploadProgress(progressEvent) {
						setUploadProgress(Math.floor(progressEvent.progress! * 100));
					},
				}
			);
		},
		onSuccess: (_, data) => {
			if (setSelectedToUpdate) setSelectedToUpdate(null);
			notification.success(saveSuccessNotification(data.name));
			const engine = engineWithFullSizeImg as EngineDto;
			const engineHadFile = !!engine.downloadLink;
			const engineReqHasFile = !!fileUploadName || !!fileUpload;

			if (deleteImage) {
				deleteEnginePicture(engine.id);
			}
			if (!engineReqHasFile && engineHadFile) {
				deleteEngineFile(engine.id);
			}

			queryClient.invalidateQueries({
				queryKey: ['engines'],
			});
		},
		onError: (error: unknown) => {
			const errorDto = getRequestError(error);
			setShowUploadModal(false);
			notification.error(
				errorNotification(generateDisplayErrorMessage(errorDto))
			);
			if (
				errorDto.code === 'ENTITY_UNIQUE_CONFLICT' &&
				errorDto.target?.includes('version')
			)
				setFormError('version', {
					type: 'custom',
					message: 'Another engine with the same version already exist',
				});
		},
	});

	const { mutate: deleteEnginePicture } = useMutation({
		mutationFn: (engineId: number) =>
			enginesService.deleteEnginesBasicPicture(engineId),
		onSuccess: () => setDeleteImage(false),
		onError: () => {
			setShowUploadModal(false);
			notification.error(errorNotification(FILE_ERROR));
		},
	});

	const { mutate: deleteEngineFile } = useMutation({
		mutationFn: (engineId: number) =>
			enginesService.deleteEnginesFile(engineId),
		onError: () => {
			setShowUploadModal(false);
			notification.error(errorNotification(FILE_ERROR));
		},
	});

	const { mutate: addEngineHandler } = useMutation({
		mutationFn: (data: FormValues) => {
			if (!isNew) return Promise.reject();
			const existingReleaseIds: Array<number> = [];
			const applicationIdsForCreation: Array<number> = [];

			selectedApplicationReleases.forEach((appToRelease) => {
				appToRelease[1] === undefined
					? applicationIdsForCreation.push(appToRelease[0])
					: existingReleaseIds.push(appToRelease[1]);
			});

			const engineDto: EngineAddedDto = {
				name: data.name,
				releaseDate: data.releaseDate,
				existingReleaseIds: existingReleaseIds,
				applicationIdsForCreation: applicationIdsForCreation,
				version: data.version === undefined ? '' : data.version,
				entityStatus: status,
				description: data.description,
				releaseNotes: data.releaseNotes,
				featured: isFeatured,
			};

			if (!!fileUpload) {
				setUploadProgress(0);
				setShowUploadModal(true);
			}

			return enginesService.createEngineForm(
				engineDto,
				selectedImage,
				fileUpload,
				{
					onUploadProgress(progressEvent) {
						setUploadProgress(Math.floor(progressEvent.progress! * 100));
					},
				}
			);
		},
		onSuccess: (_, data) => {
			if (setShowAddEngineForm) setShowAddEngineForm(false);
			notification.success(saveSuccessNotification(data.name));
			queryClient.invalidateQueries({
				queryKey: ['engines'],
			});
		},
		onError: (error: unknown) => {
			const errorDto = getRequestError(error);
			setShowUploadModal(false);
			notification.error(
				errorNotification(generateDisplayErrorMessage(errorDto))
			);
			if (
				errorDto.code === 'ENTITY_UNIQUE_CONFLICT' &&
				errorDto.target?.includes('version')
			)
				setFormError('version', {
					type: 'custom',
					message: 'Another engine with the same version already exist',
				});
		},
	});

	const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		if (setSelectedToUpdate) setSelectedToUpdate(null);
		if (setShowAddEngineForm) setShowAddEngineForm(false);
	};

	return (
		<>
			<Modal
				open={showUploadModal}
				centered
				maskClosable
				footer={null}
				afterClose={() => setShowUploadModal(false)}
				onCancel={() => setShowUploadModal(false)}
			>
				<Progress
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
					type="circle"
					percent={uploadProgress}
				/>
			</Modal>
			<form
				className={isNew ? style.addFormWrapper : style.editFormWrapper}
				onSubmit={handleSubmit((data) => {
					isNew ? addEngineHandler(data) : updateEngineHandler(data);
				})}
			>
				<div className={style.coverImgContainer}>
					<PictureField
						selectedImage={selectedImage}
						setSelectedImage={setSelectedImage}
						setCompressedFile={setCompressedFile}
						needToCompress={false}
						existingImagePath={
							isNew ? undefined : engineWithFullSizeImg.fullSizeImagePath
						}
						isImageDeleted={deleteImage}
						setIsImageDeleted={setDeleteImage}
						isAvatar={false}
					/>
					<div className={style.infoTextWrapper}>
						<TooltipBubble
							position="right"
							tooltiptext="Upload an image of proportion W/H 3.05, preferably of horisontal width > 1000 pixels"
						/>
					</div>
				</div>
				<div className={style.uploadImgContainer}>
					<UploadFileField
						text={'Upload engine'}
						fileUploadName={fileUploadName}
						setFileUploadName={setFileUploadName}
						setFileUpload={setFileUpload}
						showLoader={showUploadModal}
					/>
				</div>
				<div className={style.editForm}>
					<div className={style.column}>
						<Controller
							name="name"
							control={control}
							render={({ field }) => (
								<InputField
									placeholder={'Engine Name'}
									{...field}
									label={'Name'}
									error={!!errors.name}
									errorMessage={errors.name?.message}
								/>
							)}
						/>
						<SelectField
							defaultValue={status}
							options={[
								{ label: 'Draft', value: EntityStatuses.DRAFT },
								{ label: 'Published', value: EntityStatuses.PUBLISHED },
								{
									label: 'Published Internally',
									value: EntityStatuses.PUBLISHEDINTERNALLY,
								},
							]}
							label={'Status'}
							setSelectedField={setStatus}
						/>
					</div>
					<div className={style.column}>
						<Controller
							name="version"
							control={control}
							render={({ field }) => (
								<InputField
									placeholder={'Engine Version'}
									{...field}
									label={'Engine version'}
									error={!!errors.version}
									errorMessage={errors.version?.message}
								/>
							)}
						/>
						<Controller
							name="releaseDate"
							control={control}
							render={({ fieldState: { error } }) => (
								<>
									<InputDate
										label={'Release Date'}
										value={date}
										error={error === undefined ? false : true}
										errorMessage={error?.message}
										setDate={setDate}
									/>
									<input type="hidden" {...register('releaseDate')} />
								</>
							)}
						/>
					</div>
				</div>
				<ApplicationReleaseField
					selectedApplicationReleases={selectedApplicationReleases}
					existingEngine={isNew ? undefined : engineWithFullSizeImg}
					setSelectedApplicationReleases={setSelectedApplicationReleases}
					setLoading={setLoadingAppSection}
				/>
				<Controller
					name="description"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<InputTextarea
							{...field}
							placeholder={'Description'}
							label={'Description'}
							error={!!error}
							errorMessage={error?.message}
						/>
					)}
				/>
				<Controller
					name="releaseNotes"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<InputTextarea
							{...field}
							placeholder={'Release Notes'}
							label={'Release Notes'}
							error={!!error}
							errorMessage={error?.message}
						/>
					)}
				/>
				<div className={style.addToFavoriteWrapper}>
					<p>Featured Engine: </p>
					<div
						onClick={() => setIsFeatured((current) => !current)}
						className={`${style.addToFavoriteIcon} ${
							isFeatured ? style.active : ''
						}`}
					>
						<StarIcon />
					</div>
				</div>
				<div className={style.buttonsWrapper}>
					<McButton onClick={cancel}>Cancel</McButton>
					<McButton
						primary
						type="submit"
						disabled={!canEdit || loadingAppSection}
					>
						Save
					</McButton>
				</div>
			</form>
			{showUploadModal && <DisabledScreen />}
		</>
	);
};
